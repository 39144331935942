import { config } from './config';
import { ApiResponse, fetchHandler } from './fetchHandler';
import { postDataRequest } from './post-data-request';

export const publicSiteService = {
  getContentByPageName,
  getDisplayProducts,
  getBulkDisplayProducts,
  getOpeningHours,
  getPricing,
  sendContactForm,
  getMenuItems,
  getDisplayAdverts,
  getCustomAdverts,
};

type PageContent = {
  html: string;
  lastUpdate: string;
}

export type PageContentData = {
  PageContentId: number;
  PageName: string;
  ContentName: string;
  Html: string;
  UpdateTime: string;
  AllowEditing: boolean;
  ShowInNavMenu: boolean;
}

function getContentByPageName(pageName: string, contentName: string): Promise<PageContent> {
  return fetchHandler(`${config.apiUrl}/PublicContent/GetPageContent`, postDataRequest({ pageName: pageName, contentName: contentName }))
    .then((result) => { return result; });
}

function getMenuItems(): Promise<PageContentData[]> {
  return fetchHandler(`${config.apiUrl}/PublicContent/Ge`, postDataRequest({}))
    .then((result) => { return result });
}

function getDisplayProducts(categoryIds: number[]): Promise<any[]> {
  return fetchHandler(`${config.apiUrl}/Display/GetProductsByCategoryIds`, postDataRequest({ categoryIds }))
    .then((results) => { return results.sections; });
}

function getBulkDisplayProducts(categoryIds: number[]): Promise<any[]> {
  return fetchHandler(`${config.apiUrl}/Display/GetBulkProductsByCategoryIds`, postDataRequest({ categoryIds }))
    .then((results) => { return results.sections; });
}

function getOpeningHours(): Promise<any[]> {
  return fetchHandler(`${config.apiUrl}/PublicContent/GetOpeningHours`, postDataRequest({}))
    .then((results) => { return results.days; });
}

function getPricing(): Promise<any[]> {
  return fetch('./products.json')
    .then(products => {
      console.log(products);
      return products.json();
    });
}

function sendContactForm(name: string, emailAddress: string, phoneNumber: string, message: string): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/PublicContent/SendContactForm`, postDataRequest({ name, emailAddress, phoneNumber, message }))
    .then((result) => { return result; });
}

function getDisplayAdverts(): Promise<any> {
  return fetchHandler(`${config.apiUrl}/Display/GetAdverts`, postDataRequest({}))
    .then((results) => { return results; });
}

function getCustomAdverts(): Promise<any> {
  return fetchHandler(`${config.apiUrl}/Display/GetCustomAdverts`, postDataRequest({}))
    .then((results) => { return results; });
}
