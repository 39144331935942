import * as ko from 'knockout';

class BpFooter {

  readonly copyright = ko.observable('&copy; Gourmet Petfood Kitchen Limited');

  constructor(params: any) {
    const currentYear = new Date().getFullYear();

    this.copyright(`&copy; 2008 - ${currentYear} Gourmet Petfood Kitchen Limited.`);
  }
}

export default {
  name: 'bp-footer',
  viewModel: BpFooter,
  template: require('./bp-footer.html')
};
