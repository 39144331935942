import * as ko from 'knockout';
import cart from './cart';
import checkout from './checkout';
import checkoutComplete from './checkout-complete';
import haveYouForgotten from './have-you-forgotten';
import index from './index';


export default function () {
  ko.components.register(cart.name, cart);
  ko.components.register(checkout.name, checkout);
  ko.components.register(checkoutComplete.name, checkoutComplete);
  ko.components.register(haveYouForgotten.name, haveYouForgotten);
  ko.components.register(index.name, index);
}
