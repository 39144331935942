import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';
import router from '../../routing/router';
import routes from '../../routing/routes';
import shop from '../views/shop';
import cart from '../views/shop/cart';
import checkout from '../views/shop/checkout';
import checkoutComplete from '../views/shop/checkout-complete';
import haveYouForgotten from '../views/shop/have-you-forgotten';

class Shop {
  links: ObservableArray<any>;
  category: string;
  view: Observable<any>;

  constructor(params: any) {
    this.links = ko.observableArray(params.links);
    this.category = params.category;
    this.view = ko.observable();

    switch (params.view) {
      case cart.name: this.view({ name: cart.name, params: params }); break;
      case checkout.name: this.view({ name: checkout.name, params: params }); break;
      case checkoutComplete.name: this.view({ name: checkoutComplete.name, params: params }); break;
      case haveYouForgotten.name: this.view({ name: haveYouForgotten.name, params: params }); break;

      default: this.view({ name: shop.name, params: params }); break;
    }
  }

  actions = {
  }

  goto = {
    home: (): void => router.goto(routes.home.interpolate({}))
  }
}

export default {
  name: 'section-shop',
  viewModel: Shop,
  template: require('./shop.html')
};
