import * as ko from 'knockout';
import { Observable } from 'knockout';
import { formatWeight } from '../../../utils/format';
import { DialogParams } from '../../elements/bp-dialog';

class MainCalculator {

  readonly dialog: Observable<DialogParams | null>;

  readonly isData = ko.observable<boolean>(true);
  readonly result = ko.observable<string>('');
  readonly currentWeight = ko.observable<number>(null);

  readonly petTypes = [{ name: 'Dog' }, { name: 'Cat' }, { name: 'Puppy / Kitten (Under 18 months)' }];
  readonly selectedType = ko.observable<string>('');

  readonly activityTitle = ko.observable<string>('');
  readonly weightTitle = ko.observable<string>('');

  readonly baseActivityLevels = [
    { type: 'Dog', id: 'Dog-Immaciated', name: 'Immaciated', amount: 4, imgUrl: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/shop-displays/severely-underweight.png' },
    { type: 'Dog', id: 'Dog-Underweight', name: 'Underweight', amount: 3, imgUrl: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/shop-displays/underweight.png' },
    { type: 'Dog', id: 'Dog-Ideal', name: 'Ideal', amount: 2, imgUrl: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/shop-displays/ideal.png' },
    { type: 'Dog', id: 'Dog-Overweight', name: 'Overweight', amount: 1.5, imgUrl: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/shop-displays/overweight.png' },
    { type: 'Dog', id: 'Dog-Obese', name: 'Obese', amount: 1, imgUrl: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/shop-displays/obese.png' },

    { type: 'Cat', id: 'Cat-Immaciated', name: 'Immaciated', amount: 4, imgUrl: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/shop-displays/verythin-cat.png' },
    { type: 'Cat', id: 'Cat-Underweight', name: 'Underweight', amount: 3, imgUrl: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/shop-displays/underweight-cat.png' },
    { type: 'Cat', id: 'Cat-Ideal', name: 'Ideal', amount: 2, imgUrl: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/shop-displays/ideal-cat.png' },
    { type: 'Cat', id: 'Cat-Overweight', name: 'Overweight', amount: 1.5, imgUrl: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/shop-displays/overweight-cat.png' },
    { type: 'Cat', id: 'Cat-Obese', name: 'Obese', amount: 1, imgUrl: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/shop-displays/obese-cat.png' },
  ];
  readonly activityLevels = ko.observableArray<any>();
  readonly selectedActivity = ko.observable<string>('');
  readonly selectedAmount = ko.observable<number>(6);

  readonly breed = [
    { id: 'Small', name: 'Small (Toy breeds, small terriers, chihuahuas, etc)' },
    { id: 'Medium', name: 'Medium (Boxer, Border Collie, Spaniels, etc)' },
    { id: 'Large', name: 'Large (Labrador, German Shepherd, Farm Dogs, etc)' },
    { id: 'Giant', name: 'Giant (Newfoundland, Rottweiler, Mastiff, Great Dane, etc)' },
  ];
  readonly selectedBreed = ko.observable<string>('Medium');

  constructor(params: any) {
    this.dialog = ko.observable(null);
    this.result();

    this.selectedType.subscribe(() => {
      const levels = this.baseActivityLevels.filter(b => b.type == this.selectedType());
      this.activityLevels(levels);

      // Do this to only show cat and dog images, not puppy/kitten.
      switch (this.selectedType()) {
        case 'Dog':
        case 'Cat': this.activityTitle(`Select the image that best represents your ${this.selectedType()}'s current body condition.`); break;

        default: { this.activityTitle(''); this.selectedActivity('Dog-Ideal'); } break;
      }

      this.weightTitle(`Enter the current weight of your ${this.selectedType()}.`);
    });

  }

  actions = {
    calculate: () => {
      this.isData(false);

      if (this.currentWeight() === null || this.currentWeight() === 0) {
        this.result('We cant calculate your feeding guide without an accurate weight.');
        return;

      }

      const amountFromType = this.baseActivityLevels.filter(t => t.id === this.selectedActivity())[0];
      const amountToFeed = this.currentWeight()! * amountFromType.amount * 0.01;
      const puppyMinAmountToFeed = formatWeight(this.currentWeight()! * 0.05);
      const puppyMaxAmountToFeed = formatWeight(this.currentWeight()! * 0.08);

      const formattedAmountToFeed = formatWeight(amountToFeed);

      switch (this.selectedType()) {
        case 'Cat':
        case 'Dog': this.result(`Based on the current weight of ${this.currentWeight()}Kgs, and your ${this.selectedType()}'s body condition, you should be feeding around ${amountFromType.amount}% of their body weight, which is <strong> ${formattedAmountToFeed} of food per day</strong>.`); break;

        default: this.result(`Based on the current weight of ${this.currentWeight()}Kgs, your puppy/kitten should be eating around 4-5% of their body weight per day, and up to 8-10% per day during growth spurts.  This works out to around <strong> ${puppyMinAmountToFeed} - ${puppyMaxAmountToFeed} of food per day</strong>.`); break;
      }
    },

    reset: () => {
      this.isData(true);
      this.selectedType('');
      this.selectedActivity('');
      this.result('Calculating your results.  Please wait...');
      this.currentWeight(null);
    }
  }
}

export default {
  name: 'wp-main-calculator',
  viewModel: MainCalculator,
  template: require('./calculator.html')
};
