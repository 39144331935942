import * as ko from 'knockout';
import { Observable } from 'knockout';
import { publicSiteService } from '../../../api/service.publicsite';

class MainAnimalHomeopathy {

  readonly pageContent: Observable<string>;
  readonly announcementContent: Observable<string | null>;

  constructor(params: any) {
    this.pageContent = ko.observable('Animal Homeopathy');
    this.announcementContent = ko.observable(null);

    publicSiteService.getContentByPageName('info', 'animal-homeopathy')
      .then(result => {
        this.pageContent(result.html);
      });

    publicSiteService.getContentByPageName('generic', 'covid-info')
      .then(result => {
        this.announcementContent(result.html);
      });
  }
}

export default {
  name: 'wp-main-animal-homeopathy',
  viewModel: MainAnimalHomeopathy,
  template: require('./animal-homeopathy.html')
};
