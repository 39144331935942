import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';
import { publicSiteService } from '../../../api/service.publicsite';

class Contact {

  readonly pageContent: Observable<string>;
  readonly announcementContent: Observable<string | null>;
  readonly openingHours: ObservableArray<any>;
  readonly specialOpeningHours: ObservableArray<any>;

  readonly showForm = ko.observable<boolean>(true);
  readonly contactSubText = ko.observable<string>('');
  readonly contactSubTextClass = ko.observable<string>('');

  readonly contactName = ko.observable<string>('');
  readonly contactNameLabel = ko.observable<string>('');
  readonly contactNameClass = ko.observable<string>('');

  readonly contactEmail = ko.observable<string>('');
  readonly contactEmailLabel = ko.observable<string>('');
  readonly contactEmailClass = ko.observable<string>('');

  readonly contactPhone = ko.observable<string>('');

  readonly contactMessage = ko.observable<string>('');
  readonly contactMessageLabel = ko.observable<string>('');
  readonly contactMessageClass = ko.observable<string>('');

  constructor(params: any) {
    this.pageContent = ko.observable('Welcome to Woofles Petfood... ');
    this.announcementContent = ko.observable(null);
    this.openingHours = ko.observableArray();
    this.specialOpeningHours = ko.observableArray();

    this.resetContactForm();

    const storedUser = localStorage.getItem('user');

    if (storedUser) {
      const user = JSON.parse(storedUser);

      this.contactName(`${user.firstName} ${user.lastName}`);
      this.contactEmail(user.emailAddress);
    }



    this.loadContent();
    this.loadOpeningHours();
  }

  loadContent = () => {
    publicSiteService.getContentByPageName('contact', 'address-and-phone')
      .then(result => {
        this.pageContent(result.html);
      });

    publicSiteService.getContentByPageName('generic', 'covid-info')
      .then(result => {
        this.announcementContent(result.html);
      });
  }

  loadOpeningHours = () => {
    publicSiteService.getOpeningHours()
      .then(results => {
        const hoursMap = results
          .filter(o => !o.canBeDeleted)
          .sort((a, b) => a.displayOrder > b.displayOrder && 1 || -1)
          .map((r: any) => {
            return {
              ...r,
              day: r.day,
              formattedTime: r.isClosed ? 'CLOSED' : `${r.startTime} - ${r.endTime}`,
            };
          });

        this.openingHours(hoursMap);

        const specialHoursMap = results
          .filter(o => o.canBeDeleted)
          .sort((a, b) => a.displayOrder > b.displayOrder && 1 || -1)
          .map((r: any) => {
            return {
              ...r,
              day: r.day,
              formattedTime: r.isClosed ? 'CLOSED' : `${r.startTime} - ${r.endTime}`,
            };
          });

        this.specialOpeningHours(specialHoursMap);
      });
  }

  sendContactMessage = () => {
    // Name, Email and Message are required.

    let isValid = true;
    this.resetContactForm();

    if (this.contactName().trim() === '') {
      this.contactNameLabel(this.contactNameLabel() + ' (REQUIRED)');
      this.contactNameClass('required');
      isValid = false;
    }

    if (this.contactEmail().trim() === '') {
      this.contactEmailLabel(this.contactEmailLabel() + ' (REQUIRED)');
      this.contactEmailClass('required');
      isValid = false;
    }

    if (this.contactMessage().trim() === '') {
      this.contactMessageLabel(this.contactMessageLabel() + ' (REQUIRED)');
      this.contactMessageClass('required');
      isValid = false;
    }

    if (this.contactMessage().trim().length < 15) {
      this.contactMessageLabel(this.contactMessageLabel() + ' Message is too short.');
      this.contactMessageClass('required');
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    publicSiteService.sendContactForm(this.contactName(), this.contactEmail(), this.contactPhone(), this.contactMessage())
      .then(result => {
        console.log('result', result);

        if (result) {
          this.showForm(false);
          this.resetContactForm();
          this.contactSubText('Thank you.  Your message has been sent and we will get back to you as soon as possible.');
          this.contactSubTextClass('success');

        } else {
          // Failed 
          this.contactSubText('Sorry :( Someting went wrong and your message couldn\'t be sent.');
          this.contactSubTextClass('failed');
        }
      });

    return;
  }

  resetContactForm = () => {
    this.contactSubText('We\'d love to hear from you!');
    this.contactSubTextClass('');
    this.contactNameLabel('Name');
    this.contactNameClass('');
    this.contactEmailLabel('Email Address');
    this.contactEmailClass('');
    this.contactMessageLabel('Message');
    this.contactMessageClass('');
  }
}

export default {
  name: 'wp-main-contact',
  viewModel: Contact,
  template: require('./contact.html')
}