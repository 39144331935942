import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';

class Index {

  readonly viewHtml: Observable<string>;

  constructor(params: any) {

    this.viewHtml = ko.observable('');

  }

}

export default {
  name: 'wp-display-index',
  viewModel: Index,
  template: require('./index.html')
};
