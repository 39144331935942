import * as ko from 'knockout';
import animalHomeopathy from './animal-homeopathy';
import calculator from './calculator';
import contact from './contact';
import index from './index';
import rawFeeding from './raw-feeding';

export default function () {
  ko.components.register(animalHomeopathy.name, animalHomeopathy);
  ko.components.register(calculator.name, calculator);
  ko.components.register(contact.name, contact);
  ko.components.register(index.name, index);
  ko.components.register(rawFeeding.name, rawFeeding);
}