import * as ko from 'knockout';
import { Computed, Observable, ObservableArray } from 'knockout';
import { productsService } from '../../../api/service.products';
import { publicSiteService } from '../../../api/service.publicsite';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { formatPhoneNumber } from '../../../utils/format';
import { cart } from '../../app';
import { NotificationDialogParams } from '../../elements/wp-notification-dialog';


class ShopCheckout {
  readonly announcementContent: Observable<string | null>;
  readonly lastUpdated: Observable<string>;
  readonly notificationDialog: Observable<NotificationDialogParams | null>;

  readonly customerId: Observable<number>;
  readonly customerName: Observable<string>;
  readonly title: Observable<string>;

  readonly shippingMethods: ObservableArray<any>;
  readonly shippingMethodId: Observable<number | null>;
  readonly pickupDates: ObservableArray<any>;
  readonly pickupSlots: ObservableArray<any>;
  readonly selectedDate: Observable<string | null>;
  readonly selectedSlot: Observable<string | null>;
  readonly shippingComplete: Computed<boolean>;
  readonly shippingError: Observable<string>;
  readonly contactPhoneError: Observable<string>;

  readonly customerAddresses: ObservableArray<any>;
  readonly customerPhoneNumbers: ObservableArray<any>;
  readonly addressId: Observable<number>;
  readonly streetAddress: Observable<string | null>;
  readonly suburb: Observable<string | null>;
  readonly city: Observable<string | null>;
  readonly postCode: Observable<string | null>;
  readonly isDefaultAddress: Observable<boolean>;
  readonly addressComplete: Computed<boolean>;
  readonly addressError: Observable<string>;
  readonly contactComplete: Computed<boolean>;

  readonly isPickup = ko.observable<boolean>(false);

  readonly paymentMethods: ObservableArray<any>;
  readonly paymentMethodId: Observable<number | null>;
  readonly paymentInstructions: Observable<string>;
  readonly paymentComplete: Computed<boolean>;
  readonly paymentError: Observable<string>;

  readonly orderNotes: Observable<string>;
  readonly contactPhoneNumber: Observable<string>;

  constructor(params: any) {
    this.announcementContent = ko.observable(null);
    this.lastUpdated = ko.observable('');
    this.notificationDialog = ko.observable(null);

    this.customerId = ko.observable(0);
    this.customerName = ko.observable('');
    this.title = ko.observable('Checkout');

    this.shippingMethods = ko.observableArray();
    this.shippingMethodId = ko.observable(null);
    this.pickupDates = ko.observableArray();
    this.pickupSlots = ko.observableArray();
    this.selectedDate = ko.observable(null);
    this.selectedSlot = ko.observable(null);
    this.shippingError = ko.observable('');
    this.contactPhoneError = ko.observable('');

    this.customerAddresses = ko.observableArray();
    this.customerPhoneNumbers = ko.observableArray();
    this.addressId = ko.observable(-1);
    this.streetAddress = ko.observable(null);
    this.suburb = ko.observable(null);
    this.city = ko.observable(null);
    this.postCode = ko.observable(null);
    this.isDefaultAddress = ko.observable(true);
    this.addressError = ko.observable('');

    this.paymentMethods = ko.observableArray();
    this.paymentMethodId = ko.observable(null);
    this.paymentInstructions = ko.observable('');
    this.paymentError = ko.observable('');

    this.addressComplete = ko.pureComputed(() => {

      const streetAddress = this.streetAddress() ?? '';
      const suburb = this.suburb() ?? '';
      const city = this.city() ?? '';
      const postCode = this.postCode() ?? '';

      if (this.addressId() < 0) {
        if (this.addressId() < 0 && streetAddress?.trim().length > 0 && suburb?.trim().length > 0 && city?.trim().length > 0 && postCode?.trim().length > 0) {
          return true;
        }

        this.addressError('You have selected to add a new address, however some of the fields are not complete.');
        return false;
      }

      this.addressError('');
      return true;
    });

    this.contactComplete = ko.pureComputed(() => {
      const phoneNumber = this.contactPhoneNumber() ?? '';

      if (phoneNumber === '') {
        this.contactPhoneError('You must select an existing contact number or provide a new one for this order.');
        return false;
      }

      if (phoneNumber.length < 7) {
        this.contactPhoneError('Contact phone number is not valid.');
        return false;
      }

      this.contactPhoneError('');
      return true;
    });

    this.shippingComplete = ko.pureComputed(() => {

      if (this.isPickup()) {
        if (this.selectedDate() === '' || this.selectedSlot() === '') {

          this.shippingError('You must select a pickup date and time.');
          return false;
        }
      }

      if (this.shippingMethodId() == null || this.addressComplete() == null) {

        this.shippingError('You must select a valid shipping option.');
        return false;
      }

      this.shippingError('');
      return true;
    });

    this.paymentComplete = ko.pureComputed(() => {
      if (!this.paymentMethodId()) {
        this.paymentError('You must select a payment method.');

        return false;
      }

      this.paymentError('');
      return true;
    });

    this.orderNotes = ko.observable('');
    this.contactPhoneNumber = ko.observable('');

    this.shippingMethodId.subscribe(() => {
      this.resetShipping();
      this.isPickup(this.shippingMethodId() == 1);
    });

    this.selectedDate.subscribe(() => {
      //Reset slot if date has changed
      this.selectedSlot(null);

      if (this.selectedDate() == null) {
        return;
      }

      const dates = this.pickupDates().filter(d => d.pickupDate == this.selectedDate());

      const slots = dates[0].timeSlots.map((s: any) => {
        return {
          ...s
        }
      });

      this.pickupSlots(slots);
    });

    this.selectedSlot.subscribe(() => {
      this.shippingError('');
    });

    this.paymentMethodId.subscribe(() => {
      const paymentMethods = this.paymentMethods().filter(p => p.id === this.paymentMethodId());

      this.paymentError('');
      this.paymentInstructions(paymentMethods[0].instructions);
    });

    publicSiteService.getContentByPageName('generic', 'covid-info')
      .then(result => {
        this.announcementContent(result.html);
        this.lastUpdated(result.lastUpdate);
      });

    productsService.getShippingMethods()
      .then(results => {
        const shippingMethods = results.map(s => ({ id: s.shippingMethodId, name: s.name }));

        this.shippingMethods(shippingMethods);
      });

    productsService.getPaymentMethods()
      .then(results => {
        const paymentMethods = results.map(p => ({ id: p.paymentMethodId, name: p.name, instructions: p.webOrderInstructions }));

        this.paymentMethods(paymentMethods);
      });

    // Get pickup slots.
    productsService.getPickupSlots()
      .then(results => {
        const dateMap = results.map(s => s);

        this.pickupDates(dateMap);
      });

    // Get user addresses. 
    productsService.getCustomerAddresses()
      .then(results => {

        const addressMap = results.map(a => {

          if (a.isDefault) {
            this.addressId(a.userAddressId);
          }

          return {
            ...a,
            displayName: `${a.streetAddress}, ${a.suburb}, ${a.city}`
          }
        });

        addressMap.push({ userAddressId: -1, displayName: 'New Address', streetAddress: '', suburb: '', city: '', postCode: '', isDefault: false });

        this.customerAddresses(addressMap);

      }).catch(e => console.log(e));

    // Get user phone numbers.
    productsService.getCustomerPhoneNumbers()
      .then(results => {
        const numbers = results.map((c: any) => {

          return {
            phoneNumber: formatPhoneNumber(c.phoneNumber),
            rawPhoneNumber: c.phoneNumber
          }
        });

        this.customerPhoneNumbers(numbers);
      })
  }

  resetShipping = () => {
    this.selectedDate(null);
    this.selectedSlot(null);
  }

  actions = {
    saveOrder: (): void => {

      // Check all fields are completed.
      if (this.shippingComplete() && this.paymentComplete() && this.contactComplete()) {

        if (!this.isPickup() && this.addressId() < 0) {
          // save address and return addressId
          productsService.addCustomerAddress(this.streetAddress()!, this.suburb()!, this.city()!, this.postCode()!, this.isDefaultAddress())
            .then(result => {
              if (!result.success) {

                this.notificationDialog({
                  title: 'Something went wrong',
                  message: result.message,
                  submitText: 'Ok',
                  severityColor: 'red',
                  submitAction: () => this.notificationDialog(null)
                });

                return;
              }

              this.addressId(result.data);
            });
        }

        // Save order.
        const cartItems = cart.contents().map(c => {
          return {
            productId: c.productId,
            quantity: c.quantity,
            unitPrice: c.isOnSale ? c.salePrice : c.unitPrice
          }
        });

        let addressId: number | null = this.addressId();
        if (addressId <= 0) {
          addressId = null;
        }

        const pickupTime = this.selectedSlot() !== null ? new Date(this.selectedSlot()!).toISOString() : null;

        console.log(pickupTime);

        productsService.createWebOrder(
          this.shippingMethodId()!,
          this.paymentMethodId()!,
          addressId,
          this.orderNotes(),
          pickupTime,
          pickupTime,
          pickupTime,
          this.contactPhoneNumber(),
          cartItems
        )
          .then(result => {
            if (!result.success) {
              console.log(result.message);
              return;
            }

            this.notificationDialog({
              title: 'Order creation successful',
              message: 'Your order has successfully been created',
              submitText: 'Ok',
              severityColor: '#4b4',
              submitAction: () => this.notificationDialog(null)
            });

            //Clear cart.
            cart.empty();

            this.goto.checkoutComplete();
          });


      }

      return;
    }
  }

  goto = {
    checkoutComplete: (): void => router.goto(routes.checkoutComplete.interpolate({})),
  }
}

export default {
  name: 'wp-shop-checkout',
  viewModel: ShopCheckout,
  template: require('./checkout.html')
};