import * as crossroads from 'crossroads';
import { component } from '../components/app';
import main from '../components/sections/main';
import profile from '../components/sections/profile';
import shop from '../components/sections/shop';
import forgotPassword from '../components/views/auth/forgot-password';
import login from '../components/views/auth/login';
import register from '../components/views/auth/register';
import resetPassword from '../components/views/auth/reset-password';
import resetPasswordInstructions from '../components/views/auth/reset-password-instructions';
import validateEmail from '../components/views/auth/validate-email';
import validateEmailInstructions from '../components/views/auth/validate-email-instructions';
import code404 from '../components/views/error/404';
import calculator from '../components/views/main/calculator';
import profileIndex from '../components/views/profile/index';
import profileOrders from '../components/views/profile/orders';
import cart from '../components/views/shop/cart';
import checkout from '../components/views/shop/checkout';
import checkoutComplete from '../components/views/shop/checkout-complete';
import haveYouForgotten from '../components/views/shop/have-you-forgotten';
import pricing from '../components/views/display/pricing';
import window from '../components/views/display/window';
import contact from '../components/views/main/contact';
import animalHomeopathy from '../components/views/main/animal-homeopathy';
import rawFeeding from '../components/views/main/raw-feeding';

const debug = false;

const publicRoute: any = (component: string) => (params: any) => ({ name: component, params: params || {} });
const secureRoute: any = (component: string) => (params: any) => checkForToken()
  ? ({ name: component, params: params || {} }) : ({ name: login.name, params: {} });

const checkForToken = (): boolean => {
  const storedUser = localStorage.getItem('user');

  if (!storedUser) { return false; }

  const user = JSON.parse(storedUser);

  if (!user.token) { return false; }

  return true;
}

const routes = {

  isLoggedIn: (): boolean => { return checkForToken() },

  //public routes
  login: crossroads.addRoute('/login',
    () => { component(publicRoute(login.name)()); }
  ),

  register: crossroads.addRoute('/register',
    () => { component(publicRoute(register.name)()); }
  ),

  validateEmailInstructions: crossroads.addRoute('/validate-email-instructions',
    () => { component(publicRoute(validateEmailInstructions.name)()); }
  ),

  validateEmail: crossroads.addRoute('/validate-email?token={token}&email={email}',
    (token: string, email: string) => { component(publicRoute(validateEmail.name)({ token: token, email: email })); }
  ),

  forgotPassword: crossroads.addRoute('/forgot-password',
    () => { component(publicRoute(forgotPassword.name)()); }
  ),

  resetPasswordInstructions: crossroads.addRoute('/reset-password-instructions',
    () => { component(publicRoute(resetPasswordInstructions.name)()); }
  ),

  resetPassword: crossroads.addRoute('/reset-password?emailAddress={emailAddress}&token={token}',
    (emailAddress: string, token: string) => { component(publicRoute(resetPassword.name)({ emailAddress, token })); }
  ),

  homePage: crossroads.addRoute('', () => {
    component(publicRoute(main.name)({}));
  }),

  // main site routes - not requiring login.
  home: crossroads.addRoute('/:?={query}:',       // Added this to allow facebook references added to query.
    () => {
      // Track hits that come from facebook somehow. 
      component(publicRoute(main.name)({}));
    }
  ),

  shopByCategory: crossroads.addRoute('/shop/{category}',
    (category: string) => { component(publicRoute(shop.name)({ category: category })); }
  ),

  shop: crossroads.addRoute('/shop/',
    () => { component(publicRoute(shop.name)({ category: 'woofles-range' })); }
  ),

  shoppngCart: crossroads.addRoute('/cart',
    () => { component(publicRoute(shop.name)({ view: cart.name })); }
  ),

  contact: crossroads.addRoute('/contact-us',
    () => { component(publicRoute(main.name)({ view: contact.name })); }
  ),

  animalHomeopathy: crossroads.addRoute('/animal-homeopathy',
    () => { component(publicRoute(main.name)({ view: animalHomeopathy.name })); }
  ),

  rawFeeding: crossroads.addRoute('/raw-feeding',
    () => { component(publicRoute(main.name)({ view: rawFeeding.name })); }
  ),

  feedingCalculator: crossroads.addRoute('/feeding-calculator',
    () => { component(publicRoute(main.name)({ view: calculator.name })); }
  ),

  pricing: crossroads.addRoute('/display/pricing',
    () => { component(publicRoute(pricing.name)()); }
  ),

  window: crossroads.addRoute('/display/window',
    () => { component(publicRoute(window.name)()); }
  ),

  //secure routes

  haveYouForgotten: crossroads.addRoute('/have-you-forgotten',
    () => { component(secureRoute(shop.name)({ view: haveYouForgotten.name })); }
  ),

  checkout: crossroads.addRoute('/checkout',
    () => { component(secureRoute(shop.name)({ view: checkout.name })); }
  ),

  checkoutComplete: crossroads.addRoute('/checkout-complete',
    () => { component(secureRoute(shop.name)({ view: checkoutComplete.name })); }
  ),

  //My account
  myAccount: crossroads.addRoute('/my-account',
    () => { component(secureRoute(profile.name)({ view: profileIndex.name })); }
  ),

  accountPurchases: crossroads.addRoute('/my-account/purchases',
    () => { component(secureRoute(profile.name)({ view: profileOrders.name })); }
  ),
}

// Defaults

crossroads.bypassed.add(() => { component(publicRoute(code404.name)()); });

if (debug) {
  crossroads.routed.add(console.log, console);
}

export default routes;
