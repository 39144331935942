export function formatWeight(weightKgs: number): string {
  weightKgs = Number(weightKgs.toFixed(1));
  if (weightKgs == 0) {
    return "Single";
  } else if (weightKgs < 1) {
    return `${weightKgs * 1000}g`;
  } else if (weightKgs >= 1 && weightKgs < 2) {
    return `${weightKgs}Kg`;
  } else {
    return `${weightKgs}Kgs`;
  }
}


export function formatPrice(value: number): string {
  return `$${(value).toFixed(2)}`;
}

export function formatPriceNumber(value: number): number {
  return Number((value).toFixed(2));
}

export function zeroPad(value: number, width: number): string {
  return value.toFixed().toString().padStart(width, '0');
}

export function formatDate(value: Date | number | string | null): string {
  if (null === value) { return ''; }

  if (typeof value === 'number' || typeof value === 'string') {
    value = new Date(value);
  }

  return `${zeroPad(value.getFullYear(), 4)}-${zeroPad(value.getMonth() + 1, 2)}-${zeroPad(value.getDate(), 2)}`;
}

export function formatTime(value: Date | number | string | null, options?: { includeMs?: boolean }): string {
  if (null === value) { return ''; }

  if (typeof value === 'number' || typeof value === 'string') {
    value = new Date(value);
  }

  return `${zeroPad(value.getHours(), 2)}:${zeroPad(value.getMinutes(), 2)}:${zeroPad(value.getSeconds(), 2)}${(options && options.includeMs ? `.${zeroPad(value.getMilliseconds(), 3)}` : '')}`;
}

export function formatDateTime(value: Date | number | string | null, options?: { includeMs?: boolean }): string {
  if (null === value) { return ''; }

  return `${formatDate(value)} ${formatTime(value, options)}`;
}

export function formatShipmentNumber(value: number | null): string {
  if (null === value) { return 'SN#####'; }

  const padSize = value.toString().length > 5 ? value.toString().length : 5;

  const paddedValue = value.toString().padStart(padSize, '0');

  return `SN${paddedValue}`;
}

export function formatValue(value: number | null, precision: number) {
  return value !== null ? `${value.toFixed(precision)}` : null;
}

export function formatFromNow(time: string | Date | null): string {
  if (null == time) return '(never)';

  var unixTime = new Date(time!).getTime();
  if (!unixTime) return '(never)';

  var now = new Date().getTime();
  var difference = (unixTime / 1000) - (now / 1000);

  var tfn: any = {};

  // Check if time is in the past, present, or future
  tfn.when = 0;
  if (difference > 0) {
    tfn.when = 1;
  } else if (difference < -1) {
    tfn.when = -1;
  }

  difference = Math.abs(difference);

  if (difference / (60 * 60 * 24 * 365) > 1) {
    tfn.time = Math.floor(difference / (60 * 60 * 24 * 365));
    tfn.unitOfTime = tfn.time === 1 ? 'year' : 'years';
  } else if (difference / (60 * 60 * 24 * 45) > 1) {
    tfn.time = Math.floor(difference / (60 * 60 * 24 * 45));
    tfn.unitOfTime = tfn.time === 1 ? 'month' : 'months';
  } else if (difference / (60 * 60 * 24) > 1) {
    tfn.time = Math.floor(difference / (60 * 60 * 24));
    tfn.unitOfTime = tfn.time === 1 ? 'day' : 'days';
  } else if (difference / (60 * 60) > 1) {
    tfn.time = Math.floor(difference / (60 * 60));
    tfn.unitOfTime = tfn.time === 1 ? 'hour' : 'hours';
  } else if (difference / (60) > 1) {
    tfn.time = Math.floor(difference / (60));
    tfn.unitOfTime = tfn.time === 1 ? 'minute' : 'minutes';
  } else {
    tfn.time = Math.floor(difference);
    tfn.unitOfTime = tfn.time === 1 ? 'second' : 'seconds';
  }

  if (tfn.when === 0) {
    return 'now';
  } else if (tfn.when < 0) {
    return `${tfn.time} ${tfn.unitOfTime} ago`;
  } else {
    return ` in ${tfn.time} ${tfn.unitOfTime}`;
  }
};

export function formatSubscription(sendEmail: boolean, sendSms: boolean): string {

  return ((sendSms ? 'SMS' : '') + ' ' + (sendEmail ? 'Email' : '')).trim() || 'None';
}

export function formatPhoneNumber(phoneNumber: string): string {
  var output = "";

  var countryCode = phoneNumber.substring(0, 2);
  if (countryCode == '61' || countryCode == '64') {
    output += '(+' + countryCode + ') ';
    output += '0' + phoneNumber.substring(2);
    return output;
  }

  var regionalCode = phoneNumber.substring(0, 1);
  if (regionalCode == '9' || regionalCode == '7' || regionalCode == '6' || regionalCode == '4' || regionalCode == '3') {
    output += '(0' + regionalCode + ') ' + phoneNumber.substring(1);
    return output;
  }

  return output;
}

export function stringPadStart(value: string, length: number, character?: string): string {
  if (value.length >= length) { return value; }

  var output = '';
  character = character ? character : ' ';

  var diff = length - value.length;

  while (output.length < diff) {
    output += ' ';
  }

  return output + value;
}

export function convertPercentageToDouble(percentage: number): number {
  return percentage / 100;
}

export function convertDoubleToPercentage(double: number): number {
  return double * 100;
}

export function formatBatchNumber(value: number | null): string {
  if (null === value) { return 'BN#####'; }

  const padSize = value.toString().length > 5 ? value.toString().length : 5;

  const paddedValue = value.toString().padStart(padSize, '0');

  return `BN${paddedValue}`;
}

export function truncateText(input: string, maxLength: number) { return input.length > maxLength ? `${input.substring(0, maxLength)}     (more)&hellip;` : input; }

export function shorten(text: string, maxLength: number, delimiter: string, overflow: boolean) {
  delimiter = delimiter || "&hellip;";
  overflow = overflow || false;
  var ret = text;
  if (ret.length > maxLength) {
    var breakpoint = overflow ? maxLength + ret.substr(maxLength).indexOf(" ") : ret.substr(0, maxLength).lastIndexOf(" ");
    ret = ret.substr(0, breakpoint) + ' ' + delimiter + ' (more)';
  }
  return ret;
}