import * as ko from 'knockout';
import { Observable } from 'knockout';
import { accountsService } from '../../../api/service.accounts';
import { productsService } from '../../../api/service.products';
import { formatDateTime, formatFromNow } from '../../../utils/format';
import { dialog } from '../../app';
import { DialogParams, FieldType } from "../../elements/bp-dialog";
import { ConfirmDialogParams } from '../../elements/wp-confirm-dialog';

class Profile {
  readonly dialog: Observable<DialogParams | null>;
  readonly confirmDialog: Observable<ConfirmDialogParams | null>;
  readonly toast: Observable<string | null>;

  readonly userId = ko.observable<number>(null);
  readonly firstName = ko.observable<string>('');
  readonly lastName = ko.observable<string>('');
  readonly fullName = ko.observable<string>('');
  readonly emailAddress = ko.observable<string>('');
  readonly lastLoginTime = ko.observable<string>('never');
  readonly accountCreatedTime = ko.observable<string>('unknown');
  readonly loyaltyPointsBalance = ko.observable<number>(0);

  readonly phoneNumbers = ko.observableArray<any>();
  readonly addresses = ko.observableArray<any>();
  readonly emailGeneral = ko.observable<boolean>(false);
  readonly emailReceipts = ko.observable<boolean>(false);

  constructor() {
    this.dialog = ko.observable(null);
    this.confirmDialog = ko.observable(null);
    this.toast = ko.observable(null);

    this.loadUser();
  }

  loadUser() {
    accountsService
      .getCurrent()
      .then(profile => {
        if (!profile) {
          return;
        }

        this.userId(profile.userId);
        this.firstName(profile.firstName);
        this.lastName(profile.lastName);
        this.fullName(profile.firstName + ' ' + profile.lastName);
        this.emailAddress(profile.emailAddress);
        this.lastLoginTime(`${formatFromNow(profile.lastLoginTime)}`);
        this.accountCreatedTime(`${formatDateTime(profile.createdTime)}`)
        this.loyaltyPointsBalance(profile.loyaltyPointsGained);
        this.emailGeneral(profile.emailGeneral);
        this.emailReceipts(profile.emailReceipts);

        this.loadAddresses();
        this.loadPhoneNumbers();

      })
      .catch(e => console.log(e));
  }

  loadAddresses() {
    productsService.getCustomerAddresses()
      .then(results => {
        const addressMap = results.map(a => {
          return {
            ...a,
            fullAddress: `${a.streetAddress}, ${a.suburb}, ${a.city} ${a.postCode}`,
            isDefaultAddress: a.isDefault ? ' (Default)' : ''
          }
        });

        this.addresses(addressMap);
      });
  };

  loadPhoneNumbers() {
    accountsService.getPhoneNumbers(this.userId()!)
      .then(results => {
        const phones = results.map(p => {
          return {
            ...p,
          }
        });

        this.phoneNumbers(phones);
      });
  };

  actions = {
    toggleEmailReceipts: (): void => {
      dialog({
        title: 'Change email preferences',
        message: `Are you sure you want to turn ${(this.emailReceipts() ? 'ON' : 'OFF')} email receipts`,
        cancelText: 'No',
        submitText: 'Confirm',
        submitAction: () => {
          accountsService.setEmailReceipts(this.userId()!, this.emailReceipts())
            .then(result => {
              if (result) {

                this.emailReceipts(!this.emailReceipts());
                this.loadUser();

                dialog(null);
              }
            });
        },
        cancelAction: () => { dialog(null); }
      });
    },
    toggleEmailGeneral: (): void => {
      dialog({
        title: 'Change email preferences',
        message: `Are you sure you want to turn ${(this.emailGeneral() ? 'ON' : 'OFF')} email general`,
        cancelText: 'No',
        submitText: 'Confirm',
        submitAction: () => {
          accountsService.setEmailGeneral(this.userId()!, this.emailGeneral())
            .then(result => {
              if (result) {

                this.emailGeneral(!this.emailGeneral());
                this.loadUser();

                dialog(null);
              }
            });
        },
        cancelAction: () => { dialog(null); }
      });
    },
    addAddress: (): void => {
      const message = ko.observable<string>('');

      const streetAddressField: FieldType<string> = {
        title: 'Street Address',
        type: 'text',
        value: ko.observable(''),
        setFocus: true
      };

      const suburbField: FieldType<string> = {
        title: 'Suburb',
        type: 'text',
        value: ko.observable('')
      };

      const cityField: FieldType<string> = {
        title: 'City',
        type: 'text',
        value: ko.observable('')
      };

      const postCodeField: FieldType<string> = {
        title: 'Post Code',
        type: 'text',
        value: ko.observable('')
      };

      const isDefaultField: FieldType<string> = {
        title: 'Set as default address',
        type: 'checkbox',
        value: ko.observable(false)
      };

      dialog({
        title: 'New Address',
        message: message,
        fields: ko.observableArray([streetAddressField, suburbField, cityField, postCodeField, isDefaultField]),
        submitText: 'Save',
        cancelText: 'Cancel',
        submitAction: () => {
          let errors = '';

          const streetAddress = (streetAddressField.value() as string);
          const suburb = (suburbField.value() as string);
          const city = (cityField.value() as string);
          const postCode = (postCodeField.value() as string);
          const isDefault = (isDefaultField.value() as boolean);

          if (streetAddress === '') {
            errors += 'Street address must be provided. <br />';
          }

          if (suburb === '') {
            errors += 'Suburb must be provided. <br />';
          }

          if (city === '') {
            errors += 'City must be provided. <br />';
          }

          if (errors !== '') {
            message(`<div class="error"><ul>${errors}</ul></div>`);
            return;
          }

          accountsService.newAddress(this.userId()!, streetAddress, suburb, city, postCode, isDefault)
            .then(result => {
              if (!result) { return; }

              this.loadAddresses();

              dialog(null);

            });
        }
      });

    },
    removeAddress: (userAddressId: number): void => {
      dialog({
        title: 'Remove address from account',
        message: 'Are you sure you want to remove this delivery addrress? This action is irreversible.',
        cancelText: 'No',
        submitText: 'Confirm',
        submitAction: () => {
          accountsService.removeAddress(this.userId()!, userAddressId)
            .then(result => {
              if (result) {
                this.loadAddresses();

                dialog(null);
              }
            });
        },
        cancelAction: () => { dialog(null); }
      });
    },
    setAsDefault: (userAddressId: number): void => {
      dialog({
        title: 'Set address as default',
        message: 'Are you sure you want to set this address as the default address?',
        cancelText: 'No',
        submitText: 'Confirm',
        submitAction: () => {
          accountsService.setAsDefault(this.userId()!, userAddressId)
            .then(result => {
              if (result) {
                this.loadAddresses();

                dialog(null);
              }
            });
        },
        cancelAction: () => { dialog(null); }
      });
    },
    addPhoneNumber: (): void => {
      const message = ko.observable<string>('Enter the phone number you wish to add.');

      const phoneNumberField: FieldType<string> = {
        title: 'Phone Number',
        type: 'number',
        value: ko.observable(''),
        setFocus: true
      };

      dialog({
        title: 'New Phone Number',
        message: message,
        fields: ko.observableArray([phoneNumberField]),
        submitText: 'Save',
        cancelText: 'Cancel',
        submitAction: () => {
          let errors = '';

          const phoneNumber = (phoneNumberField.value() as string);

          if (phoneNumber === '') {
            errors += 'Phone number must be provided. <br />';
          }

          if (Number(phoneNumber) == NaN) {
            errors += 'Phone number is invalid. <br />';
          }

          if (errors !== '') {
            message(`<div class="error"><ul>${errors}</ul></div>`);
            return;
          }

          accountsService.newPhoneNumber(this.userId()!, phoneNumber)
            .then(result => {
              if (!result) { return; }

              this.loadPhoneNumbers();

              dialog(null);

            });
        }
      });

    },
    removePhoneNumber: (userPhoneNumberId: number): void => {
      dialog({
        title: 'Remove phone number from account',
        message: 'Are you sure you want to remove this phone number? This action is irreversible.',
        cancelText: 'No',
        submitText: 'Confirm',
        submitAction: () => {
          accountsService.removePhoneNumber(this.userId()!, userPhoneNumberId)
            .then(result => {
              if (result) {
                this.loadPhoneNumbers();

                dialog(null);
              }
            });
        },
        cancelAction: () => { dialog(null); }
      });

    },
    updateEmail: (): void => {
      const message = ko.observable('Are you sure you want to change your email address?');

      const emailAddressField: FieldType<string> = {
        title: 'Email Address',
        type: 'text',
        value: ko.observable(this.emailAddress()),
        setFocus: true
      };

      dialog({
        title: 'Update email address',
        message: message,
        fields: ko.observableArray([emailAddressField]),
        cancelText: 'No',
        submitText: 'Confirm',
        submitAction: () => {
          let errors = '';
          const emailAddress = (emailAddressField.value() as string);

          if (emailAddress === '') {
            errors += 'Email Address is required';
          }

          if (errors !== '') {
            message(`<div class="error"><ul>${errors}</ul></div>`);
            return;
          }

          accountsService.setEmailAddress(this.userId()!, emailAddress)
            .then(result => {
              console.log(result);

              if (result.success) {
                this.loadUser();
                this.loadAddresses();

                dialog(null);
                return;
              }

              message(`<div class="error"><ul>${result.message}</ul></div>`);
              return;
            })
            .catch(e => {
              message(`<div class="error"><ul>${e.message}</ul></div>`);
              return;
            });
        },
        cancelAction: () => { dialog(null); }
      });
    },

  }
}

export default {
  name: 'bp-profile',
  viewModel: Profile,
  template: require('./index.html')
};
