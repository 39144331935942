import { Observable, Computed } from 'knockout';
import * as ko from 'knockout';

class WpSubHeaderLink {
  action: () => void;
  mainIcon: string;
  hoverIcon: string;
  label: string;
  icon: Computed<any>;
  enable: Observable<boolean>;
  isHover: Observable<boolean>;

  constructor(params: any) {
    this.action = params.action;

    this.mainIcon = params.icon;
    this.hoverIcon = params.altIcon;
    this.isHover = ko.observable(false);

    this.icon = ko.pureComputed(() => this.isHover() && this.hoverIcon != null
      ? this.hoverIcon
      : this.mainIcon);

    this.label = params.label;

    this.enable = ko.isObservable(params.enable)
      ? params.enable : ko.observable(true);
  }

  hoverOn = () => {
    this.isHover(true);
  }

  hoverOff = () => {
    this.isHover(false);
  }
}

export default {
  name: 'wp-sub-header-link',
  viewModel: WpSubHeaderLink,
  template: require('./wp-sub-header-link.html')
};
