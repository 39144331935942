import * as ko from 'knockout';
import { Observable } from 'knockout';

class BpLinkLarge {
  action: () => void;
  label: string;
  class?: string;
  enable: Observable<boolean>;
  iconName: string;
  title: string;

  constructor(params: any) {
    this.iconName = params.iconName || 'icon/chevron-right';

    this.action = params.action;
    this.label = params.label;
    this.class = params.class;
    this.title = params.title || '';

    this.enable = ko.isObservable(params.enable)
      ? params.enable : ko.observable(true);
  }
}

export default {
  name: 'bp-link-large',
  viewModel: BpLinkLarge,
  template: require('./bp-link-large.html')
};
