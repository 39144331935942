import * as ko from 'knockout';
import { Observable } from 'knockout';
import { publicSiteService } from '../../../api/service.publicsite';
import { DialogParams } from '../../elements/bp-dialog';

class MainIndex {

  readonly dialog: Observable<DialogParams | null>;
  readonly pageContent: Observable<string>;
  readonly announcementContent: Observable<string | null>;
  readonly lastUpdated: Observable<string>;

  constructor(params: any) {
    this.dialog = ko.observable(null);
    this.pageContent = ko.observable('Welcome to Woofles Petfood... ');
    this.announcementContent = ko.observable(null);
    this.lastUpdated = ko.observable('');

    // call service to get page content.
    publicSiteService.getContentByPageName('home', 'main-content')
      .then(result => {
        this.pageContent(result.html);
      });

    publicSiteService.getContentByPageName('generic', 'covid-info')
      .then(result => {
        this.announcementContent(result.html);
      });
  }

  goto = {
    //login: (): void => router.goto(routes.login.interpolate({})),
    //register: (): void => router.goto(routes.register.interpolate({}))
  }
}

export default {
  name: 'wp-main-index',
  viewModel: MainIndex,
  template: require('./index.html')
};
