import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';
import { publicSiteService } from '../../../api/service.publicsite';
import { displayImageBasePath, productImageBasePath } from '../../../utils/const';
import { formatPrice, formatWeight } from '../../../utils/format';

type ProductBlockType = {
  heading: string;
  headingImage: Observable<string>;
  products: ProductType[];
}

type VideosImagesType = {
  type: string;
  url: string;
  timeoutSecs: number;
}

type ProductType = {
  productId: number;
  product: string;
  size: string;
  pricing: string;
  unitPrice: number;
  specialPricing: string;
  isOnSpecial: boolean;
  isCat: boolean;
  isDog: boolean;
  imageUrl: string | null;
  weight: number;
}

class Pricing {
  readonly productBlock1: ObservableArray<ProductBlockType>;
  readonly productBlock2: ObservableArray<ProductBlockType>;
  readonly productBlock3Products: ObservableArray<ProductType>;
  readonly videoImagesArray: ObservableArray<VideosImagesType>;
  readonly selectedVideoImage: Observable<VideosImagesType | null>;
  readonly allProductsArray: ObservableArray<ProductType>;
  readonly adProduct = ko.observable<string>('');
  readonly adPricing = ko.observable<string>('');
  readonly adSpecial = ko.observable<string>('');
  readonly adImage = ko.observable<string>('');
  readonly adDescription = ko.observable<string>('');
  readonly selectedAdvertProductId = ko.observable<number>(0);

  readonly clockTime = ko.observable<string>('');

  readonly doodleImage = ko.observable(`${displayImageBasePath}/doodledoodle.png`);
  readonly logoImage = ko.observable(`${displayImageBasePath}/NZ-Black-Logo.png`);

  readonly tempAdverts: any[] = [];

  readonly blockComplete = {
    block1: ko.observable<boolean>(false),
    block2: ko.observable<boolean>(false),
    block3: ko.observable<boolean>(false)
  };

  readonly iteration = 0;
  block3Timeout: NodeJS.Timeout | null;

  constructor() {
    this.productBlock1 = ko.observableArray();
    this.productBlock2 = ko.observableArray();
    this.productBlock3Products = ko.observableArray();
    this.allProductsArray = ko.observableArray();
    this.block3Timeout = null;

    this.videoImagesArray = ko.observableArray();
    this.selectedVideoImage = ko.observable(null);

    this.tempAdverts.push({ index: 0, productId: 91, pricingPerKg: "$6.10/Kg", imageUrl: `${productImageBasePath}/new-product.png`, product: 'Multi Meat Rolls', description: '<br />50% Boneless beef & lamb. <br />50% Offal (Liver, Kidney, Heart, Tongue & Tripe)<br /><strong>Available in bulk only.</strong>' });
    this.tempAdverts.push({ index: 2, productId: 92, pricingPerKg: "$5.00/Kg", imageUrl: `${productImageBasePath}/new-product.png`, product: 'Lamb, Beef & Chicken Rolls', description: '<br />Lamb, Beef and chicken rolls, with added garlic.<br /><strong>Available in bulk only.</strong>' });
    this.tempAdverts.push({ index: 2, productId: 7, pricingPerKg: "$7.50/Kg", imageUrl: `${productImageBasePath}/new-product.png`, product: 'Minced Tripe Rolls', description: '<br />Minced Ovine Tripe. An essential part of any raw food diet<br /><strong>Available in bulk only.</strong>' });
    this.tempAdverts.push({ index: 2, productId: 1, pricingPerKg: "$7.50/Kg", imageUrl: `${productImageBasePath}/new-product.png`, product: 'Beef Mince Rolls', description: '<br />Beef mince rolls.<br /><strong>Available in bulk only.</strong>' });

    // Get current time.
    this.getTime();

    const updateAdverts = () => {
      if (this.blockComplete.block1() && this.blockComplete.block2() && this.blockComplete.block3()) {
        this.getAdverts(0);
      }
    }

    this.blockComplete.block1.subscribe(updateAdverts);
    this.blockComplete.block2.subscribe(updateAdverts);
    this.blockComplete.block3.subscribe(updateAdverts);

    this.getProducts();

    //this.productBlock3.subscribe(() => {
    //  clearTimeout(this.block3Timeout!);

    //  this.startBlock3(0);
    //});

    this.videoImagesArray.subscribe(() => {
      clearTimeout(this.block3Timeout!);

      this.startBlock3(0);
    });

    //this.getAdverts(0);
  }

  getTime = () => {
    const currentTime = new Date();

    this.clockTime(currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
    setTimeout(this.getTime, 1000);
  }

  getProducts = () => {

    this.getBlock1();
    this.getBlock2();
    this.getBlock3();

    this.getVideosImages();

    //setTimeout(this.getProducts, 60000 * 15);
    setTimeout(() => window.location.reload(), 60000 * 15);
  }

  getBlock1 = async () => {
    const blockSections: ProductBlockType[] = [];

    await publicSiteService.getDisplayProducts([9])
      .then(results => {
        results.forEach(s => {

          const products: ProductType[] = s.products.map((p: any) => {

            const isOnSale = p.salePrice > 0;

            return {
              productId: p.productId,
              product: p.name,
              salePrice: p.salePrice,
              isOnSale: p.salePrice > 0,
              size: formatWeight(p.unitWeight),
              pricing: isOnSale ? `Now ${formatPrice(p.salePrice)}` : formatPrice(p.retailRRP),
              isCat: p.isCat,
              isDog: p.isDog,
              imageUrl: p.imageUrl != null ? `${productImageBasePath}/${p.imageUrl}` : '',
              weight: p.unitWeight,
              unitPrice: isOnSale ? p.salePrice : p.retailRRP,
              fontSize: p.name.length > 35 ? 0.5 : 0.6,
            }
          });

          const image = ko.observable('');

          const images = products.filter(p => p.imageUrl != '').map(p => p.imageUrl);
          if (images.length > 0) {
            const rand = Math.floor(Math.random() * images.length);
            image(images[rand] as string);
          }

          blockSections.push({ heading: s.title, headingImage: image, products });
        });
      });

    await publicSiteService.getBulkDisplayProducts([9])
      .then(results => {
        results.forEach(s => {

          const products: ProductType[] = s.products.map((p: any) => {

            const isOnSale = p.salePrice > 0;

            return {
              productId: p.productId,
              product: p.name,
              salePrice: p.salePrice,
              isOnSale: p.salePrice > 0,
              size: formatWeight(p.unitWeight),
              pricing: isOnSale ? `Now ${formatPrice(p.salePrice)}` : formatPrice(p.retailRRP),
              isCat: p.isCat,
              isDog: p.isDog,
              imageUrl: p.imageUrl != null ? `${productImageBasePath}/${p.imageUrl}` : '',
              weight: p.unitWeight,
              unitPrice: isOnSale ? p.salePrice : p.retailRRP,
              fontSize: p.name.length > 35 ? 0.55 : 0.65,
            }
          });

          const image = ko.observable('');

          const images = products.filter(p => p.imageUrl != null).map(p => p.imageUrl);
          if (images.length > 0) {
            const rand = Math.floor(Math.random() * images.length);
            image(images[rand] as string);
          }
          blockSections.push({ heading: s.title, headingImage: image, products });
        });
      });

    this.productBlock1(blockSections);
    this.populateAllProductsArray(blockSections);
    this.blockComplete.block1(true);
    //setTimeout(this.getBlock1, 60000 * 15);
  }

  getBlock2 = async () => {
    const blockSections: ProductBlockType[] = [];

    await publicSiteService.getDisplayProducts([8])
      .then(results => {
        results.forEach(s => {

          const products: ProductType[] = s.products.map((p: any) => {

            const isOnSale = p.salePrice > 0;

            return {
              productId: p.productId,
              product: p.name,
              salePrice: p.salePrice,
              isOnSale: p.salePrice > 0,
              size: formatWeight(p.unitWeight),
              pricing: isOnSale ? `Now ${formatPrice(p.salePrice)}` : formatPrice(p.retailRRP),
              isCat: p.isCat,
              isDog: p.isDog,
              imageUrl: p.imageUrl != null ? `${productImageBasePath}/${p.imageUrl}` : '',
              weight: p.unitWeight,
              unitPrice: isOnSale ? p.salePrice : p.retailRRP,
              fontSize: p.name.length > 40 ? 0.55 : 0.65,
            }
          });

          const image = ko.observable('');

          const images = products.filter(p => p.imageUrl != '').map(p => p.imageUrl);

          if (images.length > 0) {
            const rand = Math.floor(Math.random() * images.length);
            image(images[rand] as string);
          }

          const chunkSize = 28;
          for (let i = 0; i < products.length; i += chunkSize) {
            const chunk: ProductType[] = products.slice(i, i + chunkSize);

            blockSections.push({ heading: s.title, headingImage: image, products: chunk });
          }
        });
      });

    this.productBlock2(blockSections);

    this.populateAllProductsArray(blockSections);
    this.blockComplete.block2(true);

    //setTimeout(this.getBlock2, 60000 * 15);
  }

  getBlock3 = async () => {
    const products: ProductType[] = [];

    await publicSiteService.getCustomAdverts()
      .then(results => {

        const advertProducts: ProductType[] = results.map((p: any) => {

          const isOnSale = p.salePrice > 0;

          return {
            productId: p.productId,
            product: p.name,
            salePrice: p.salePrice,
            isOnSale: isOnSale,
            size: formatWeight(p.unitWeight),
            pricing: isOnSale ? `Now ${formatPrice(p.salePrice)}` : formatPrice(p.retailRRP),
            isCat: p.isCat,
            isDog: p.isDog,
            imageUrl: p.imageUrl != null ? `${displayImageBasePath}/${p.imageUrl}` : '',
            weight: p.unitWeight,
            unitPrice: isOnSale ? p.salePrice : p.retailRRP,
            fontSize: p.name.length > 40 ? 0.55 : 0.65,
          }
        });

        products.push(...advertProducts);
      });

    this.productBlock3Products(products);

    this.blockComplete.block3(true);
  }

  getVideosImages = () => {
    clearTimeout(this.block3Timeout!);

    const list: VideosImagesType[] = [];

    list.push({ type: 'image', url: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/15years.jpg', timeoutSecs: 10000 });
    list.push({ type: 'video', url: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/VealBrisket.mp4', timeoutSecs: 11000 });
    list.push({ type: 'video', url: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/dogs.mp4', timeoutSecs: 8000 });
    list.push({ type: 'image', url: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/15years.jpg', timeoutSecs: 10000 });
    list.push({ type: 'video', url: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/CyrusEating.mp4', timeoutSecs: 18000 });
    list.push({ type: 'image', url: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/15years.jpg', timeoutSecs: 10000 });
    list.push({ type: 'video', url: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/RockyEating.mp4', timeoutSecs: 21000 });
    list.push({ type: 'image', url: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/15years.jpg', timeoutSecs: 10000 });
    list.push({ type: 'video', url: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/Pressups.mp4', timeoutSecs: 12000 });
    list.push({ type: 'image', url: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/trio.jpg', timeoutSecs: 10000 });
    list.push({ type: 'video', url: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/CyrusPuppyEating.mp4', timeoutSecs: 18000 });
    list.push({ type: 'image', url: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/15years.jpg', timeoutSecs: 10000 });
    list.push({ type: 'image', url: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/rockylola.jpg', timeoutSecs: 10000 });
    list.push({ type: 'video', url: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/Tank1.mp4', timeoutSecs: 16000 });
    list.push({ type: 'image', url: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/stuffedFrame.jpg', timeoutSecs: 10000 });
    list.push({ type: 'image', url: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/15years.jpg', timeoutSecs: 10000 });
    list.push({ type: 'video', url: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/CyrusNovContB.mp4', timeoutSecs: 22000 });
    list.push({ type: 'image', url: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/theboys.jpg', timeoutSecs: 10000 });
    list.push({ type: 'image', url: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/rockytux.jpg', timeoutSecs: 10000 });
    list.push({ type: 'image', url: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/15years.jpg', timeoutSecs: 10000 });
    list.push({ type: 'video', url: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/DogDiving.mp4', timeoutSecs: 5000 });
    list.push({ type: 'image', url: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/kingute.jpg', timeoutSecs: 10000 });
    list.push({ type: 'image', url: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/boys.jpg', timeoutSecs: 10000 });
    list.push({ type: 'image', url: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/15years.jpg', timeoutSecs: 10000 });
    list.push({ type: 'image', url: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/cylog.jpg', timeoutSecs: 10000 });
    list.push({ type: 'video', url: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/Hanging.mp4', timeoutSecs: 25000 });
    list.push({ type: 'image', url: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/cyrusabbey.jpg', timeoutSecs: 10000 });

    this.videoImagesArray(list);

    this.startBlock3(0);
  }

  startBlock3 = (iteration: number) => {

    if (iteration == this.videoImagesArray().length) {
      iteration = 0;
    }
    this.selectedVideoImage(this.videoImagesArray()[iteration]);

    iteration++;

    this.block3Timeout = setTimeout(() => this.startBlock3(iteration), this.selectedVideoImage()?.timeoutSecs);
  }

  populateAllProductsArray = (sections: ProductBlockType[]) => {
    const allProducts = this.allProductsArray();

    sections.forEach(s => {
      s.products.forEach((p: ProductType) => {

        //if (p.imageUrl !== '') {
        //  allProducts.push(p);
        //}

        allProducts.push(p);
      });
    });

    this.allProductsArray(allProducts);
  }

  getAdverts = (currentIndex: number) => {
    // TEMPORARY PLACE HOLDER!!
    if (currentIndex == this.tempAdverts.length) {
      currentIndex = 0;
    }
    const advert = this.tempAdverts[currentIndex];

    // lookup price from product pricing.
    const product = this.allProductsArray().filter(p => p.productId == advert.productId);

    const perKgPrice = product[0].unitPrice / product[0].weight;

    this.adImage(advert.imageUrl);
    this.adPricing(`${formatPrice(perKgPrice)}/Kg`);
    this.adProduct(advert.product);
    this.adDescription(advert.description);

    this.selectedAdvertProductId(advert.productId);

    currentIndex++;

    /* this.adProduct(this.clockTime());*/
    setTimeout(() => this.getAdverts(currentIndex), 15000);
  }
}

export default {
  name: 'display-pricing',
  viewModel: Pricing,
  template: require('./pricing.html')
};
