import * as ko from 'knockout';
import { Computed, Observable, ObservableArray } from 'knockout';
import { productsService } from '../../../api/service.products';
import { publicSiteService } from '../../../api/service.publicsite';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { productImageBasePath, productImagePlaceholder } from '../../../utils/const';
import { formatPrice, formatWeight } from '../../../utils/format';
import { CartItem } from '../../../utils/shoppingCart';
import { cart } from '../../app';
import { DialogParams } from '../../elements/bp-dialog';
import { NotificationDialogParams } from '../../elements/wp-notification-dialog';

class ShopCart {
  readonly dialog: Observable<DialogParams | null>;
  readonly notificationDialog: Observable<NotificationDialogParams | null>;
  readonly announcementContent: Observable<string | null>;
  readonly lastUpdated: Observable<string>;
  readonly title: Observable<string>;
  readonly products: ObservableArray<any>;
  readonly cartProducts: ObservableArray<CartItem>;
  readonly cartTotal: Observable<number>;
  readonly cartSavings: Observable<number>;
  readonly formattedCartTotal: Computed<string>;
  readonly formattedCartSavings: Computed<string>;
  readonly hasSavings: Computed<boolean>;

  constructor(params: any) {
    this.dialog = ko.observable(null);
    this.notificationDialog = ko.observable(null);
    this.announcementContent = ko.observable(null);
    this.lastUpdated = ko.observable('');
    this.title = ko.observable('Your shopping cart');
    this.products = ko.observableArray();
    this.cartProducts = ko.observableArray();
    this.cartTotal = ko.observable(0);
    this.cartSavings = ko.observable(0);
    this.formattedCartTotal = ko.pureComputed(() => formatPrice(this.cartTotal()));
    this.formattedCartSavings = ko.pureComputed(() => formatPrice(this.cartSavings()));
    this.hasSavings = ko.pureComputed(() => cart.contents().some(c => c.isOnSale));

    publicSiteService.getContentByPageName('generic', 'covid-info')
      .then(result => {
        this.announcementContent(result.html);
        this.lastUpdated(result.lastUpdate);
      });

    productsService.getProducts()
      .then(result => {
        this.products(result.map(p => p));
      });

    this.updateCartItems();
  }

  updateCartItems = () => {
    let total = 0;
    const cartProducts = cart.contents().map(c => {

      const lineTotal = ko.computed(() => c.isOnSale ? formatPrice(c.salePrice * c.quantity) : formatPrice(c.unitPrice * c.quantity));

      return {
        ...c,
        productName: c.product,
        formattedPrice: formatPrice(c.unitPrice),
        formattedWeight: formatWeight(c.unitWeight),
        formattedImageUrl: c.imageUrl == null ? `${productImageBasePath}/${productImagePlaceholder}` : `${productImageBasePath}/${c.imageUrl}`,
        formattedLineTotal: lineTotal,
        isOnSale: c.salePrice > 0,
        formattedSalePrice: `SPECIAL: ${formatPrice(c.salePrice)}`
      }
    });

    this.cartTotal(cart.total());
    this.cartSavings(cart.savings());

    this.cartProducts(cartProducts.sort((a, b) => a.productName > b.productName ? 1 : a.productName < b.productName ? -1 : 0));
  }

  goto = {
    details: (productId: number): void => router.goto(routes.shop.interpolate({})),
    shopWooflesRange: (): void => router.goto(routes.shopByCategory.interpolate({ category: 'woofles-range' })),
    checkout: (): void => {
      if (this.cartProducts().length == 0) {
        return;
      }

      router.goto(routes.haveYouForgotten.interpolate({}));
    },

    clearCart: (): void => {
      cart.empty();
    }
  }

  actions = {
    updateCart: (productId: number, quantity: number): number => {
      const product = cart.findInCart(productId);

      if (null == product) {
        return 0;
      }

      const value = cart.update(productId, this.cartProducts(), quantity);
      this.updateCartItems();
      return value;
    },

    incrementCart: (productId: number): number => {
      const quantity = cart.add(productId, this.cartProducts(), 1);
      this.updateCartItems();
      return quantity;
    },

    decrementCart: (productId: number): number => {
      const quantity = cart.decrease(productId, 1);
      this.updateCartItems();
      return quantity;
    }
  }
}

export default {
  name: 'wp-shop-cart',
  viewModel: ShopCart,
  template: require('./cart.html')
};
