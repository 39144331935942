import * as ko from 'knockout';
import index from './index';
import orders from './orders';
import security from './security';


export default function () {
  ko.components.register(index.name, index);
  ko.components.register(orders.name, orders);
  ko.components.register(security.name, security);
}
