import * as ko from 'knockout';
import { Observable } from 'knockout';
import router from '../../routing/router';
import routes from '../../routing/routes';
import mainAnimalHomeopathy from '../views/main/animal-homeopathy';
import calculator from '../views/main/calculator';
import mainContact from '../views/main/contact';
import mainIndex from '../views/main/index';
import mainRawFeeding from '../views/main/raw-feeding';

class Main {
  readonly view: Observable<any>;

  constructor(params: any) {
    this.view = ko.observable();

    switch (params.view) {
      case mainContact.name: this.view({ name: mainContact.name }); break;
      case calculator.name: this.view({ name: calculator.name }); break;
      case mainAnimalHomeopathy.name: this.view({ name: mainAnimalHomeopathy.name }); break;
      case mainRawFeeding.name: this.view({ name: mainRawFeeding.name }); break;

      default: this.view({ name: mainIndex.name }); break;
    }
  }

  actions = {
  }

  goto = {
    home: (): void => router.goto(routes.home.interpolate({}))
  }
}

export default {
  name: 'section-main',
  viewModel: Main,
  template: require('./main.html')
};
