import * as crossroads from 'crossroads';
import * as ko from 'knockout';
import { Observable } from 'knockout';
import { userService } from '../../../api/service.auth';
import router from '../../../routing/router';
import routes from '../../../routing/routes';

class Login {
  email: Observable<string>;
  password: Observable<string>;

  errors = {
    response: ko.observable(''),
    email: ko.observable(''),
    password: ko.observable(''),
    hasResponse: ko.pureComputed(() => false),
    hasEmail: ko.pureComputed(() => false),
    hasPassword: ko.pureComputed(() => false),
    failedValidation: ko.pureComputed(() => false)
  };

  constructor() {
    this.email = ko.observable('');
    this.password = ko.observable('');

    this.errors.hasResponse = ko.pureComputed(() => this.errors.response().length > 0);
    this.errors.hasEmail = ko.pureComputed(() => this.errors.email().length > 0);
    this.errors.hasPassword = ko.pureComputed(() => this.errors.password().length > 0);
    this.errors.failedValidation = ko.pureComputed(() => this.errors.hasEmail() || this.errors.hasPassword());
  }

  actions = {
    login: (): void => {
      this.errors.email(this.email() ? '' : 'Email is required.');

      this.errors.password(this.password() ? '' : 'Password is required.');

      if (this.errors.failedValidation()) {
        return;
      }

      userService.login(this.email(), this.password())
        .then((user) => {

          crossroads.resetState();

          if (routes.login.match(window.location.pathname + window.location.search)) {
            router.goto(routes.homePage.interpolate({}));
            return;
          }

          router.goto(window.location.pathname + window.location.search);
          this.errors.response('');

          //router.goto(routes.home.interpolate({}));
        })
        .catch((e) => {
          console.log(e);

          this.errors.response('Login failed. Please check your details.');
        });
    }
  }

  goto = {
    forgotPassword: (): void => router.goto(routes.forgotPassword.interpolate({})),
    register: (): void => router.goto(routes.register.interpolate({}))
  }
}

export default {
  name: 'login',
  viewModel: Login,
  template: require('./login.html')
};
