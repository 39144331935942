import * as ko from 'knockout';
import { Observable } from 'knockout';
import { debug } from 'webpack';
import { userService } from '../../../api/service.auth';
import router from '../../../routing/router';
import routes from '../../../routing/routes';

class Register {
  firstName: Observable<string>;
  lastName: Observable<string>;
  email: Observable<string>;
  phoneNumber: Observable<string>;
  submittingText: Observable<string>;

  errors = {
    response: ko.observable(''),
    firstName: ko.observable(''),
    lastName: ko.observable(''),
    email: ko.observable(''),
    phoneNumber: ko.observable(''),

    hasResponse: ko.pureComputed(() => false),
    hasFirstName: ko.pureComputed(() => false),
    hasLastName: ko.pureComputed(() => false),
    hasEmail: ko.pureComputed(() => false),
    hasPhoneNumber: ko.pureComputed(() => false),
    failedValidation: ko.pureComputed(() => false)
  };

  constructor() {
    this.firstName = ko.observable('');
    this.lastName = ko.observable('');
    this.email = ko.observable('');
    this.phoneNumber = ko.observable('');
    this.submittingText = ko.observable('');

    this.errors.hasResponse = ko.pureComputed(() => this.errors.response().length > 0);
    this.errors.hasFirstName = ko.pureComputed(() => this.errors.firstName().length > 0);
    this.errors.hasLastName = ko.pureComputed(() => this.errors.lastName().length > 0);
    this.errors.hasEmail = ko.pureComputed(() => this.errors.email().length > 0);
    this.errors.hasPhoneNumber = ko.pureComputed(() => this.errors.phoneNumber().length > 0);

    this.errors.failedValidation = ko.pureComputed(() =>
      this.errors.hasFirstName() || this.errors.hasLastName()
      || this.errors.hasEmail() || this.errors.hasPhoneNumber());
  }

  replaceAll = (text: string, find: string, replace: string): string => {
    return text.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }

  escapeRegExp = (text: string): string => {
    return text.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  actions = {
    register: (): void => {
      this.errors.response('');

      this.errors.firstName(this.firstName() ? '' : 'First Name is required.');
      this.errors.lastName(this.lastName() ? '' : 'Last Name is required.');
      this.errors.email(this.email() ? '' : 'Email is required.');

      var numbers = /^([0-9]{2,3})([0-9]{6,})+$/;
      var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      this.phoneNumber(this.replaceAll(this.phoneNumber(), '(', ''));
      this.phoneNumber(this.replaceAll(this.phoneNumber(), ')', ''));
      this.phoneNumber(this.replaceAll(this.phoneNumber(), ' ', ''));
      this.phoneNumber(this.phoneNumber().trim());

      if (this.phoneNumber() === '' || this.phoneNumber().match(numbers)) {
        this.errors.phoneNumber('');
      } else {
        this.errors.phoneNumber('Phone number is not a valid number.');
      }

      if (this.email().match(emailRegex)) {
        this.errors.email('');
      } else {
        this.errors.email('Email address is not a valid email format.');
      }

      if (this.errors.failedValidation()) { return; }

      this.submittingText('Registering details and sending verification email...');

      var user = {
        firstName: this.firstName(),
        lastName: this.lastName(),
        email: this.email(),
        phoneNumber: this.phoneNumber()
      };

      console.log('user', user);

      userService.register(user.firstName, user.lastName, user.email, user.phoneNumber)
        .then((result) => {
          this.submittingText('');

          router.goto(routes.validateEmailInstructions.interpolate({}));
        })
        .catch(() => {
          this.submittingText('');
          this.errors.response('Registration failed. Please try again.');
        });
    }
  }

  goto = {
    cancel: (): void => router.goto(routes.home.interpolate({}))
  }
}

export default {
  name: 'register',
  viewModel: Register,
  template: require('./register.html')
};
