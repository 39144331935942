import { config } from './config';
import { ApiResponse, fetchHandler } from './fetchHandler';
import { postDataRequest } from './post-data-request';
import { authPostDataRequest } from './auth-post-data-request';

export const productsService = {
  getProducts,
  getCartProducts,
  getShippingMethods,
  getPickupSlots,
  getCustomerAddresses,
  getCustomerPhoneNumbers,
  getPaymentMethods,
  addCustomerAddress,
  createWebOrder,
  saveOrderItem
};

export type ProductResponse = {
  isActive: boolean;
  isCat: boolean;
  isDog: boolean;
  productId: number;
  name: string;
  productCategoryId: number;
  categoryName: string;
  description: string;
  unitWeight: number;
  retailRRP: number;
  salePrice: number;
  saleQuantity: number;
  isLoyaltyItem: boolean;
  isBulk: boolean;
  criticalLevelMin: number;
  warningLevelMin: number;
  isWebsite: boolean;
  isRetail: boolean;
  applyWastage: boolean;
  wastageCalc: number;
  groupingId: number;
  groupingName: string;
  packagingDetails: string;
  totalManufactureCost: string;
  totalMargin: string;
  imageUrl: string;
  images: string[];
  isFreightItem: boolean;
}

export type CartProductResponse = {
  productId: number;
  productName: string;
  unitPrice: number;
  isActive: boolean;
  primaryImageUrl: string;
}

function getProducts(): Promise<ProductResponse[]> {
  return fetchHandler(`${config.apiUrl}/shop/getProducts`, postDataRequest({}))
    .then((result) => { return result.products; });
}

function getCartProducts(productIds: number[]): Promise<CartProductResponse[]> {
  return fetchHandler(`${config.apiUrl}/shop/getCartProducts`, postDataRequest({ productIds }))
    .then((result) => { return result; });
}

export type ShippingMethodResponse = {
  shippingMethodId: number;
  name: string;
  isDeliveryOption: boolean;
  isFreightOption: boolean;
  isPickupOption: boolean;
}

function getShippingMethods(): Promise<ShippingMethodResponse[]> {
  return fetchHandler(`${config.apiUrl}/shop/getShippingMethods`, postDataRequest({}))
    .then((results) => { return results; });
}

export type PaymentMethodResponse = {
  paymentMethodId: number;
  name: string;
  webOrderInstructions: string;
}

function getPaymentMethods(): Promise<PaymentMethodResponse[]> {
  return fetchHandler(`${config.apiUrl}/shop/getPaymentMethods`, postDataRequest({}))
    .then((results) => { return results; });
}

export type PickupTimesResponse = {
  displayName: string;
  pickupDate: string;
  timeSlots: PickupSlot[];
}

export type PickupSlot = {
  startTime: string;
  endTime: string;
}

function getPickupSlots(): Promise<PickupTimesResponse[]> {
  return fetchHandler(`${config.apiUrl}/shop/getPickupTimes`, postDataRequest({}))
    .then((results) => { return results; });
}

export type Address = {
  userAddressId: number;
  streetAddress: string;
  suburb: string;
  city: string;
  postCode: string;
  isDefault: boolean;
}

function getCustomerAddresses(): Promise<Address[]> {
  return fetchHandler(`${config.apiUrl}/shop/getUserAddresses`, authPostDataRequest({}))
    .then((results) => { return results; });
}

function getCustomerPhoneNumbers(): Promise<string[]> {
  return fetchHandler(`${config.apiUrl}/shop/getUserPhoneNumbers`, authPostDataRequest({}))
    .then((results) => { return results; });
}

function addCustomerAddress(streetAddress: string, suburb: string, city: string, postCode: string, isDefault: boolean): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/shop/addCustomerAddress`, authPostDataRequest({ streetAddress, suburb, city, postCode, isDefault }))
    .then((result) => { return result; });
}

function createWebOrder(shippingMethodId: number, paymentMethodId: number, addressId: number | null, orderNotes: string, pickupDate: string | null, pickupTime: string | null, pickupTimeIsoString: string | null, phoneNumber: string, cartItems: any[]): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/shop/createWebOrder`, authPostDataRequest({ shippingMethodId, paymentMethodId, addressId, orderNotes, pickupDate, pickupTime, pickupTimeIsoString, phoneNumber, cartItems }))
    .then((result) => { return result; });
}

function saveOrderItem(): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/shop/createWebOrder`, authPostDataRequest({}))
    .then((result) => { return result; });
}
