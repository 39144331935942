import * as ko from 'knockout';
import { Observable } from 'knockout';
import { publicSiteService } from '../../../api/service.publicsite';

class MainRawFeeding {

  readonly pageContent: Observable<string>;
  readonly announcementContent: Observable<string | null>;

  constructor(params: any) {
    this.pageContent = ko.observable('About Raw Feeding');
    this.announcementContent = ko.observable(null);

    // call service to get page content.
    publicSiteService.getContentByPageName('info', 'raw-feeding')
      .then(result => {
        this.pageContent(result.html);
      });

    publicSiteService.getContentByPageName('generic', 'covid-info')
      .then(result => {
        this.announcementContent(result.html);
      });
  }

  goto = {
    //login: (): void => router.goto(routes.login.interpolate({})),
    //register: (): void => router.goto(routes.register.interpolate({}))
  }
}

export default {
  name: 'wp-main-raw-feeding',
  viewModel: MainRawFeeding,
  template: require('./raw-feeding.html')
};
