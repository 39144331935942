import * as ko from 'knockout';
import { Observable } from 'knockout';
import { accountsService } from '../../../api/service.accounts';
import { formatDate, formatDateTime, formatPrice } from '../../../utils/format';

type Purchase = {
  transactionDate: string;
  type: string;
  status: string;
  items: number;
  amount: number;
}

class ProfileOrders {
  readonly purchases = ko.observableArray<Purchase>();

  constructor() {

    this.loadOrders();
  }

  loadOrders() {
    accountsService.getPurchaseHistory()
      .then(results => {
        const purchaseMap = results.map(p => {
          return {
            ...p,
            formattedAmmount: formatPrice(p.amount),
            formattedTime: formatDate(p.transactionDate)
          }
        });

        this.purchases(purchaseMap);
      });
  };

}

export default {
  name: 'bp-profile-orders',
  viewModel: ProfileOrders,
  template: require('./orders.html')
};
