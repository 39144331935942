import registerAuth from './auth/_index';
import registerDisplay from './display/_index';
import registerError from './error/_index';
import registerMain from './main/_index';
import registerProfile from './profile/_index';
import registerShop from './shop/_index';

export default function () {
  registerAuth();
  registerDisplay();
  registerError();
  registerMain();
  registerProfile();
  registerShop();
}
