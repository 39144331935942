import * as ko from 'knockout';
import { Computed, Observable, ObservableArray, PureComputed } from 'knockout';
import { userService } from '../../api/service.auth';
import { PageContentData, publicSiteService } from '../../api/service.publicsite';
import router from '../../routing/router';
import routes from '../../routing/routes';
import { cart, component } from '../app';

class WpHeader {
  logo: Computed<string>;
  isShop: Computed<boolean>;
  isLoggedIn: Computed<boolean>;

  cartCount: Observable<number>;

  mobileNavOpen: Observable<boolean>;

  highlight: {
    home: ko.PureComputed<boolean>;
    shop: ko.PureComputed<boolean>;
    contact: ko.PureComputed<boolean>;
    calculator: ko.PureComputed<boolean>;
    cart: ko.PureComputed<boolean>;
    account: ko.PureComputed<boolean>;
    rawFeeding: ko.PureComputed<boolean>;
    animalHomeopathy: ko.PureComputed<boolean>;
  };

  constructor(params: any) {
    this.logo = ko.pureComputed(() => params.logo ? ko.unwrap(params.logo) : '');

    this.isShop = ko.pureComputed(() => window.location.pathname.indexOf('/shop') === 0);

    this.isLoggedIn = ko.pureComputed(() => routes.isLoggedIn());

    this.mobileNavOpen = ko.observable(false);

    this.cartCount = cart.count;

    this.highlight = {
      home: ko.pureComputed(() => {
        component();

        return window.location.pathname.indexOf('/home') === 0
          || window.location.pathname.length === 1;
      }),
      shop: ko.pureComputed(() => {
        component();

        return window.location.pathname.indexOf('/shop') === 0;
      }),
      contact: ko.pureComputed(() => {
        component();

        return window.location.pathname.indexOf('/contact-us') === 0;
      }),
      calculator: ko.pureComputed(() => {
        component();

        return window.location.pathname.indexOf('/feeding-calculator') === 0;
      }),
      cart: ko.pureComputed(() => {
        component();

        return window.location.pathname.indexOf('/cart') === 0;
      }),
      account: ko.pureComputed(() => {
        component();

        return window.location.pathname.indexOf('/account') === 0;
      }),
      rawFeeding: ko.pureComputed(() => {
        component();

        return window.location.pathname.indexOf('/raw-feeding') === 0;
      }),
      animalHomeopathy: ko.pureComputed(() => {
        component();

        return window.location.pathname.indexOf('/animal-homeopathy') === 0;
      }),
    };

    const roles = ko.observableArray<string>();

    const storedUser = localStorage.getItem('user');

    if (storedUser) {
      const user = JSON.parse(storedUser);

      if (!user.roles) { return; }

      roles(user.roles);
    }
  }

  actions = {
    logout: (): void => {
      userService.logout();

      router.goto(routes.home.interpolate({}));
    },

    closeMobileNav: (): void => {
      console.log('Closing mobile nav');
      this.mobileNavOpen(false);
    },

    openMobileNav: (): void => {
      console.log('Opening mobile nav');
      this.mobileNavOpen(true);
    },

  }

  goto = {
    home: (): void => router.goto(routes.homePage.interpolate({})),
    shop: (): void => router.goto(routes.shopByCategory.interpolate({ category: 'woofles-range' })),
    shopWooflesRange: (): void => router.goto(routes.shopByCategory.interpolate({ category: 'woofles-range' })),
    shopWooflesBulk: (): void => router.goto(routes.shopByCategory.interpolate({ category: 'woofles-bulk' })),
    shopHomeopathics: (): void => router.goto(routes.shopByCategory.interpolate({ category: 'homeopathics' })),
    shopTreats: (): void => router.goto(routes.shopByCategory.interpolate({ category: 'treats' })),
    shopSupplements: (): void => router.goto(routes.shopByCategory.interpolate({ category: 'supplements' })),
    shopFreight: (): void => router.goto(routes.shopByCategory.interpolate({ category: 'freight-costs' })),
    contact: (): void => router.goto(routes.contact.interpolate({})),
    calculator: (): void => router.goto(routes.feedingCalculator.interpolate({})),
    account: (): void => router.goto(routes.myAccount.interpolate({})),
    accountHistory: (): void => router.goto(routes.accountPurchases.interpolate({})),
    cart: (): void => router.goto(routes.shoppngCart.interpolate({})),
    rawFeeding: (): void => router.goto(routes.rawFeeding.interpolate({})),
    animalHomeopathy: (): void => router.goto(routes.animalHomeopathy.interpolate({})),
  }
}

export default {
  name: 'wp-header',
  viewModel: WpHeader,
  template: require('./wp-header.html')
};
