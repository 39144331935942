import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';

export type ConfirmDialogParams = {
  dialog?: Observable<ConfirmDialogParams | null>;
  title: Observable<string> | string;
  message: Observable<string> | string;
  cancelText: Observable<string> | string;
  submitText: Observable<string> | string;
  submitAction: () => void;
  cancelAction?: () => void;
};

class WpConfirmDialog {
  readonly title: Observable<string>;
  readonly message: Observable<string>;
  readonly cancelText: Observable<string>;
  readonly submitText: Observable<string>;
  readonly errors: Observable<string>;
  readonly submitAction: () => void;
  readonly cancelAction?: () => void;

  constructor(params: ConfirmDialogParams) {
    this.title = ko.isObservable(params.title) ? params.title : ko.observable(params.title);
    this.message = ko.isObservable(params.message) ? params.message : ko.observable(params.message);
    this.cancelText = ko.isObservable(params.cancelText) ? params.cancelText : ko.observable(params.cancelText);
    this.submitText = ko.isObservable(params.submitText) ? params.submitText : ko.observable(params.submitText);
    this.submitAction = params.submitAction;
    this.cancelAction = params.cancelAction ? params.cancelAction : params.dialog && params.dialog(null);

    this.errors = ko.observable('');
  }
}

export default {
  name: 'wp-confirm-dialog',
  viewModel: WpConfirmDialog,
  template: require('./wp-confirm-dialog.html')
};
