import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';
import { productsService } from '../../../api/service.products';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { productImageBasePath, productImagePlaceholder } from '../../../utils/const';
import { formatPrice, formatWeight, shorten } from '../../../utils/format';
import { cart } from '../../app';
import { NotificationDialogParams } from '../../elements/wp-notification-dialog';


class ShopHaveYouForgotten {

  readonly customerId: Observable<number>;
  readonly customerName: Observable<string>;
  readonly productsToUpsell: ObservableArray<any>;
  readonly notificationDialog: Observable<NotificationDialogParams | null>;

  constructor(params: any) {

    this.customerId = ko.observable(0);
    this.customerName = ko.observable('');
    this.productsToUpsell = ko.observableArray();
    this.notificationDialog = ko.observable(null);

    this.getAndFilterProducts();
  }

  getAndFilterProducts = () => {
    productsService.getProducts()
      .then(results => {

        const cartItems = cart.contents();

        const doesCartHaveBones = cartItems.some(c => c.productCategoryId === 9);
        const doesCartHaveTreats = cartItems.some(c => c.productCategoryId === 16);
        const doesCartHaveSupplements = cartItems.some(c => c.productCategoryId === 17);
        const doesCartHaveBoneBroth = cartItems.some(c => c.productId === 25);
        const doesCartHaveTripe = cartItems.some(c => c.productId === 12);

        // Filter out any products that are already in the cart.
        const availableProducts = results
          .filter((p) => cartItems.some((cp) => cp.productId !== p.productId))
          .map(p => {
            var productIcons = [];

            if (p.isDog) { productIcons.push({ icon: 'icon/dog', title: 'Suitable for dogs' }); }
            if (p.isCat) { productIcons.push({ icon: 'icon/cat', title: 'Suitable for cats' }); }
            if (p.isLoyaltyItem) { productIcons.push({ icon: 'icon/loyalty', title: 'This item will count towards your loyalty rewards.' }); }

            const isFocussed = ko.observable(false);

            return {
              ...p,
              formattedPrice: formatPrice(p.retailRRP),
              formattedWeight: formatWeight(p.unitWeight),
              icons: ko.observableArray(productIcons),
              formattedImageUrl: p.imageUrl == null ? `${productImageBasePath}/${productImagePlaceholder}` : `${productImageBasePath}/${p.imageUrl}`,
              visible: p.packagingDetails != '',
              unitPrice: p.retailRRP,
              isFocussed: isFocussed,
              formattedDescription: shorten(p.description, 150, '&hellip;', true)
            }
          });

        const productsToUpsell: any[] = [];

        if (!doesCartHaveBones) {
          // get 2 random bone products
          const prods = this.getRandomProducts(availableProducts.filter(a => a.productCategoryId === 9 && !a.isBulk), 2).map((d: any) => ({ ...d, title: 'Need bones for dental care?' }));
          prods.forEach(p => productsToUpsell.push(p));
        }

        if (!doesCartHaveTripe) {
          // get tripe
          const prods = this.getRandomProducts(availableProducts.filter(a => a.productId === 12 || a.productId === 23), 1).map((d: any) => ({ ...d, title: 'Need tripe?' }));
          prods.forEach(p => productsToUpsell.push(p));
        }

        if (!doesCartHaveTreats) {
          // get 2 random treats
          const prods = this.getRandomProducts(availableProducts.filter(a => a.productCategoryId === 16), 2).map((d: any) => ({ ...d, title: 'How about some treats?' }));
          prods.forEach(p => productsToUpsell.push(p));
        }

        if (!doesCartHaveBoneBroth) {
          // get bone broth
          const prods = availableProducts.filter(a => a.productId === 25);
          if (prods.length == 1) {
            productsToUpsell.push(prods.map((d: any) => ({ ...d, title: 'Bone broth is good for gut health' }))[0]);
          }
        }

        if (!doesCartHaveSupplements) {
          // get supplements 
          const prods = this.getRandomProducts(availableProducts.filter(a => a.productCategoryId === 17), 2).map((d: any) => ({ ...d, title: 'Supplements' }));
          prods.forEach(p => productsToUpsell.push(p));
        }

        const upsellListSize = 8;
        if (productsToUpsell.length < upsellListSize) {
          var diff = upsellListSize - productsToUpsell.length;
          // get diff number of other remaining products that aren't already in the upsell list.

          const prods = this.getRandomProducts(availableProducts.filter(ap => productsToUpsell.some(up => up.productId !== ap.productId)), diff).map((d: any) => ({ ...d, title: 'Try something new!!' }));
          prods.forEach(p => productsToUpsell.push(p));
        }

        this.productsToUpsell(productsToUpsell);
        console.log(this.productsToUpsell());

      });
  };

  actions = {
    updateCart: (productId: number, quantity: number): number => {
      const product = cart.findInCart(productId);

      if (null == product) {
        return 0;
      }

      return cart.update(productId, this.productsToUpsell(), quantity);
    },

    incrementCart: (productId: number): number => {
      console.log(this.productsToUpsell());

      return cart.add(productId, this.productsToUpsell(), 1);
    },

    decrementCart: (productId: number): number => {
      return cart.decrease(productId, 1);
    }

  }

  goto = {
    checkout: (): void => {

      if (routes.isLoggedIn()) {
        router.goto(routes.checkout.interpolate({}));
        return;
      }

      // Open a dialog to say they will be redirected to the login page.
      this.notificationDialog({
        title: 'Checkout',
        message: 'To proceed with checkout you will need to log in to your Woofles account.',
        submitText: 'Ok',
        submitAction: () => router.goto(routes.checkout.interpolate({})),
        severityColor: '#4CAF50'
      });
    },
  }

  getRandomProducts = (products: any[], count: number): any[] => {
    var returnArray: any[] = [];

    let index = 0;
    let productList = products;

    while (index < count) {

      // get item from items array.
      const product = productList[Math.floor(Math.random() * productList.length)];
      // push onto returnArray.
      returnArray.push(product);
      // remove item from items array.
      productList = productList.filter(p => p.productId !== product.productId);
      // continue.

      index++;
    }

    return returnArray;
  }
}

export default {
  name: 'wp-shop-have-your-forgotten',
  viewModel: ShopHaveYouForgotten,
  template: require('./have-you-forgotten.html')
};