import { authPostDataRequest } from './auth-post-data-request';
import { config } from './config';
import { ApiResponse, fetchHandler } from './fetchHandler';

export const accountsService = {
  getCurrent,
  newAddress,
  removeAddress,
  setAsDefault,
  setEmailAddress,
  verifyChangedEmailAddress,
  getPhoneNumbers,
  removePhoneNumber,
  newPhoneNumber,
  setEmailReceipts,
  setEmailGeneral,
  getPurchaseHistory,
};

type AccountResponse = {
  userId: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  emailGeneral: boolean;
  emailReceipts: boolean;
  isActive: boolean;
  lastLoginTime: string;
  loyaltyPointsGained: number;
  createdTime: string;
}

type PurchaseHistory = {
  transactionId: number;
  transactionDate: string;
  type: string;
  status: string;
  items: number;
  amount: number;
}

type PhoneNumberResponse = {
  userPhoneNumberId: number;
  phoneNumber: string;
  phoneNumberType: string;
}

// current user
function getCurrent(): Promise<AccountResponse> {
  return fetchHandler(`${config.apiUrl}/user/current`, authPostDataRequest({}))
    .then(account => { return account; });
}

function newAddress(userId: number, streetAddress: string, suburb: string, city: string, postCode: string, isDefault: boolean): Promise<number | null> {
  return fetchHandler(`${config.apiUrl}/user/createAddress`, authPostDataRequest({ userId, streetAddress, suburb, city, postCode, isDefault }))
    .then(address => { return address; });
}

function removeAddress(userId: number, userAddressId: number): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/user/deleteAddress`, authPostDataRequest({ userId, userAddressId }))
    .then(result => { return result; });
}

function setAsDefault(userId: number, userAddressId: number): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/user/SetAddressAsDefault`, authPostDataRequest({ userId, userAddressId }))
    .then(result => { return result; });
}

function setEmailAddress(userId: number, emailAddress: string): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/user/setEmailAddress`, authPostDataRequest({ userId, emailAddress }))
    .then(result => { return result; });
}

function verifyChangedEmailAddress(emailAddress: string, token: string): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/user/verifyEmailChange`, authPostDataRequest({ emailAddress, token }))
    .then(result => { return result; });
}

function getPhoneNumbers(userId: number): Promise<PhoneNumberResponse[]> {
  return fetchHandler(`${config.apiUrl}/user/getPhoneNumbers`, authPostDataRequest({ userId }))
    .then(results => { return results; });
}

function newPhoneNumber(userId: number, phoneNumber: string): Promise<number | null> {
  return fetchHandler(`${config.apiUrl}/user/createPhoneNumber`, authPostDataRequest({ userId, phoneNumber }))
    .then(address => { return address; });
}

function removePhoneNumber(userId: number, userPhoneNumberId: number): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/user/deletePhoneNumber`, authPostDataRequest({ userId, userPhoneNumberId }))
    .then(result => { return result; });
}

function setEmailReceipts(userId: number, emailReceipts: boolean): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/user/setEmailPreferences`, authPostDataRequest({ userId, emailReceipts }))
    .then(result => { return result; });
}

function setEmailGeneral(userId: number, emailGeneral: boolean): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/user/setEmailPreferences`, authPostDataRequest({ userId, emailGeneral }))
    .then(result => { return result; });
}

function getPurchaseHistory(): Promise<PurchaseHistory[]> {
  return fetchHandler(`${config.apiUrl}/user/getPurchases`, authPostDataRequest({}))
    .then(results => { return results; });
}
