import * as ko from 'knockout';
import { Observable } from 'knockout';
import router from '../../../routing/router';
import routes from '../../../routing/routes';


class ShopCheckoutComplete {
  readonly title: Observable<string>;

  constructor(params: any) {
    this.title = ko.observable('Checkout Complete');
  }

  goto = {
    accountHistory: (): void => router.goto(routes.accountPurchases.interpolate({})),
  }
}

export default {
  name: 'wp-shop-checkout-complete',
  viewModel: ShopCheckoutComplete,
  template: require('./checkout-complete.html')
};