import * as ko from 'knockout';

import index from './index';
import pricing from './pricing';
import window from './window';

export default function () {
  ko.components.register(index.name, index);
  ko.components.register(pricing.name, pricing);
  ko.components.register(window.name, window);
}
