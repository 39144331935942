import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';
import router from '../../routing/router';
import routes from '../../routing/routes';
import profileIndex from '../views/profile/index';
import profileOrders from '../views/profile/orders';
import profileSecurity from '../views/profile/security';

class Profile {
  view: Observable<any>;
  links: ObservableArray<any>;

  constructor(params: any) {
    this.view = ko.observable('');

    switch (params.view) {
      case profileSecurity.name:
        this.view({ name: profileSecurity.name, params: params });
        break;

      case profileOrders.name:
        this.view({ name: profileOrders.name, params: params });
        break;

      default:
        this.view(profileIndex.name);
        break;
    }

    this.links = ko.observableArray(params.links);
    this.links([
      { action: () => router.goto(routes.myAccount.interpolate({})), label: 'Details', icon: 'icon/profile', description: 'Profile details' },
      { action: () => router.goto(routes.accountPurchases.interpolate({})), label: 'Purchases', icon: 'icon/shopping-cart', description: 'Transactions' },
    ]);
  }

}

export default {
  name: 'section-profile',
  viewModel: Profile,
  template: require('./profile.html')
};
