import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';
import { publicSiteService } from '../../../api/service.publicsite';
import { displayImageBasePath } from '../../../utils/const';

type VideosImagesType = {
  type: string;
  content: string;
  timeoutSecs: number;
  color: string;
  showFullScreen: boolean;
}

class Window {
  readonly doodleImage = ko.observable(`${displayImageBasePath}/doodledoodle-head.png`);
  readonly logoImage = ko.observable(`${displayImageBasePath}/NZ-Black-Logo.png`);

  readonly videoImagesArray: ObservableArray<VideosImagesType>;
  readonly selectedVideoImage: Observable<VideosImagesType | null>;
  readonly selectedBackgroundColor: Observable<string | null>;

  readonly iteration = 0;
  rotationTimeout: NodeJS.Timeout | null;
  imageRotationTimeout: NodeJS.Timeout | null;

  readonly headerImageArray: ObservableArray<string>;
  readonly backgroundColorArray: ObservableArray<string>;

  constructor() {

    this.videoImagesArray = ko.observableArray();
    this.selectedVideoImage = ko.observable({ type: 'image', color: 'white', content: 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/videos/15years.jpg', timeoutSecs: 600000, showFullScreen: false });
    this.headerImageArray = ko.observableArray();
    this.selectedBackgroundColor = ko.observable('#7cf9fe');

    this.rotationTimeout = null;
    this.imageRotationTimeout = null;

    this.backgroundColorArray = ko.observableArray();

    this.getVideosImages();
    this.getHeaderImages();

    this.videoImagesArray.subscribe(() => {
      clearTimeout(this.rotationTimeout!);

      this.startRotation(0, 0);
    });
  }

  getHeaderImages = () => {

    clearTimeout(this.imageRotationTimeout!);
    const images = [`${displayImageBasePath}/doodledoodle-head.png`,
    `${displayImageBasePath}/cyrusdoodle.png`,
    `${displayImageBasePath}/rockydoodle.png`,
    `${displayImageBasePath}/ledgedoodle.png`];

    const colours = ['#FFC300', '#37B0FF', '#37B0FF', '#6495ED', '#00FFFF', '#7cf9fe', '#cf2146'];

    const shuffled = this.shuffleImages(images);
    const shuffledColours = this.shuffleImages(colours);

    this.headerImageArray(shuffled);
    this.backgroundColorArray(shuffledColours);

    this.startImageRotation(0);
  }

  getVideosImages = () => {
    clearTimeout(this.rotationTimeout!);

    publicSiteService.getDisplayAdverts()
      .then(result => {
        const list = result.content.map((c: any) => {
          return { type: c.type, content: c.content, timeoutSecs: c.milliseconds, color: c.color, showFullScreen: c.showFullScreen };
        });

        const shuffled = this.shuffle(list);

        this.videoImagesArray(shuffled);

        this.startRotation(0, 0);
      });
  }

  shuffle = (array: VideosImagesType[]) => {
    let currentIndex = array.length;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {

      // Pick a remaining element...
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  shuffleImages = (array: string[]) => {
    let currentIndex = array.length;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {

      // Pick a remaining element...
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  startImageRotation = (iteration: number) => {
    if (iteration == this.headerImageArray().length) {
      iteration = 0;
    }

    this.doodleImage(this.headerImageArray()[iteration]);

    iteration++;

    this.imageRotationTimeout = setTimeout(() => this.startImageRotation(iteration), 20000);
  }

  startRotation = (iteration: number, colourIteration: number) => {
    if (iteration == this.videoImagesArray().length) {
      iteration = 0;
    }

    if (colourIteration == this.backgroundColorArray().length) {
      colourIteration = 0;
    }

    this.selectedVideoImage(this.videoImagesArray()[iteration]);
    this.selectedBackgroundColor(this.backgroundColorArray()[colourIteration]);

    colourIteration++;
    iteration++;

    this.rotationTimeout = setTimeout(() => this.startRotation(iteration, colourIteration), this.selectedVideoImage()?.timeoutSecs);
  }
}

export default {
  name: 'display-window',
  viewModel: Window,
  template: require('./window.html')
};
