import * as ko from 'knockout';
import { Observable } from 'knockout';
import { userService } from '../../../api/service.auth';
import router from '../../../routing/router';
import routes from '../../../routing/routes';

class ForgetPwd {
  email: Observable<string>;

  errors = {
    response: ko.observable(''),
    email: ko.observable(''),
    hasResponse: ko.pureComputed(() => false),
    hasEmail: ko.pureComputed(() => false),
    failedValidation: ko.pureComputed(() => false)
  };

  constructor() {
    this.email = ko.observable('');

    this.errors.hasResponse = ko.pureComputed(() => this.errors.response().length > 0);
    this.errors.hasEmail = ko.pureComputed(() => this.errors.email().length > 0);
    this.errors.failedValidation = ko.pureComputed(() => this.errors.hasEmail());
  }

  actions = {
    sendReset: (): void => {
      this.errors.email(this.email() ? '' : 'Email is required.');

      if (this.errors.failedValidation()) { return; }

      console.log('emailaddress', this.email());

      userService.requestReset(this.email())
        .then(() => {

          router.goto(routes.resetPasswordInstructions.interpolate({}));
        })
        .catch(() => {
          this.errors.response('Something went wrong - reset failed.');
        });
    }
  }

  goto = {
    cancel: (): void => router.goto(routes.login.interpolate({}))
  }
}

export default {
  name: 'forgot-password',
  viewModel: ForgetPwd,
  template: require('./forgot-password.html')
};
