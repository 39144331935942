import * as ko from 'knockout';
import { Observable } from 'knockout';
import index from '../views/display/index';
import pricing from '../views/display/pricing';

class Display {
  view: Observable<any>;
  maxWidth = ko.observable<number>(0);
  maxHeight = ko.observable<number>(0);

  constructor(params: any) {
    this.view = ko.observable();

    switch (params.view) {

      case pricing.name: this.view({ name: pricing.name, params: params });

      case window.name: this.view({ name: window.name, params: params });

      default: this.view({ name: index.name, params: params }); break;
    }

    this.CalculateViewArea();
  }

  CalculateViewArea = () => {
    const width = window.innerWidth || document.documentElement.clientWidth ||
      document.body.clientWidth;
    const height = window.innerHeight || document.documentElement.clientHeight ||
      document.body.clientHeight;

    this.maxWidth(width - 20);
    this.maxHeight(height - 20);
  }
}

export default {
  name: 'section-display',
  viewModel: Display,
  template: require('./display.html')
};
