import * as ko from 'knockout';
import display from './display';
import main from './main';
import profile from './profile';
import shop from './shop';

export default function () {
  ko.components.register(display.name, display);
  ko.components.register(main.name, main);
  ko.components.register(profile.name, profile);
  ko.components.register(shop.name, shop);
}
