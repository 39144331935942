import { config } from './config';
import { ApiResponse, fetchHandler } from './fetchHandler';
import { postDataRequest } from './post-data-request';

export const userService = {
  login,
  logout,
  register,
  verifyEmail,
  requestReset,
  resetPassword,
  validateResetToken,
  refreshToken,
};

function login(emailAddress: string, secret: string): Promise<any> {
  return fetchHandler(`${config.apiUrl}/user/authenticate`, postDataRequest({ emailAddress, secret }))
    .then(user => {
      let userDetails = {};
      // login successful if there's a jwt token in the response
      if (user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes

        userDetails = {
          firstName: user.firstName,
          lastName: user.lastName,
          isAdmin: user.isAdmin,
          isCashRegister: user.isCashRegister,
          token: user.token,
          emailAddress: emailAddress
        };

        localStorage.setItem('user', JSON.stringify(userDetails));
      }
      /*console.log(userDetails);*/
      return userDetails;
    });
}

function register(firstName: string, lastName: string, emailAddress: string, phoneNumber: string): Promise<any> {
  return fetchHandler(`${config.apiUrl}/user/register`, postDataRequest({ firstName, lastName, emailAddress, phoneNumber }));
}

function verifyEmail(token: string, email: string): Promise<any> {
  return fetchHandler(`${config.apiUrl}/user/validate-email`, postDataRequest({ token, email }));
}

function requestReset(emailAddress: string): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/user/forgotPassword`, postDataRequest({ emailAddress }));
}

function resetPassword(token: string, email: string, password: string, confirmPassword: string): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/user/resetPassword`, postDataRequest({ token, email, password, confirmPassword }));
}

function validateResetToken(token: string): Promise<any> {
  return fetchHandler(`${config.apiUrl}/auth/validate-reset-token`, postDataRequest({ token }));
}

function refreshToken(): Promise<any> {
  return fetchHandler(`${config.apiUrl}/auth/refresh-token`, postDataRequest({}));
}

export function logout() {
  localStorage.removeItem('user');
}
